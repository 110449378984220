import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getUserById } from "../../services/stores/actions/content";
import { useDispatch } from "react-redux";
import { setSelectedDiscussion } from "../../services/stores/actions/chat";

const ModalGroupDiscussion = ({
  listUserBlocked,
  show,
  handleClose,
  isWeb,
  history,
}) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);

  const getAllUsersBlocked = () => {
    if (listUserBlocked.length) {
      const promises = listUserBlocked.map((blockedModel) => {
        return getUserById(blockedModel.user)
          .then((user) => {
            return user.data;
          })
          .catch((error) => {
            console.log(error);
          });
      });
      Promise.all(promises).then((listUsers) => {
        setUsers(listUsers);
      });
    }
  };

  const handlerLeaveGroup = () => {
    dispatch(setSelectedDiscussion(null));
    if (isWeb) handleClose();
    else
      history.push({
        pathname: `/messages`,
      });
  };

  useEffect(() => {
    getAllUsersBlocked();
  }, [listUserBlocked]);

  return (
    <Modal show={show} onHide={() => {
      handleClose(); 
      history.push('/messages');
    }}>
      <Modal.Header>
        <h3>Group</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="mbd_list_blocked d-flex flex-wrap">
          {users.map((user) => {
            return (
              <img
                src={user.profil}
                className="img-fluid rounded-circle rounded_35 mr-2"
                alt="user"
              />
            );
          })}
        </div>
        <p>
          You blocked some members in this group. Do you want to continue or
          leave the group?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={handleClose}
          className="btn btn-yellow btn-rounded k-semibold-12 ml-1"
        >
          Continue
        </button>
        <button
          onClick={handlerLeaveGroup}
          className="btn btn-yellow btn-rounded k-semibold-12 ml-1"
        >
          Leave
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGroupDiscussion;
