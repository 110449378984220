import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import CGU from '../../views/CGU/cgu'
import Close from '@material-ui/icons/Close'

function ModalCGU() {
    const [show, setShow] = useState()

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    return (
        <>
            <span onClick={handleShow} style={{ color: '#606060', cursor: 'pointer', fontWeight: 'bold' }}>TOS</span>
            <Modal
                show={show}
                onHide={handleClose}
                size='lg'
                aria-labelledby="contained-modal-litle-vcenter"
                centered
                // style={{ position: 'relative' }}
            >
                <div style={{ 
                    position: 'absolute',
                    top: '-20px', 
                    right: '-20px', 
                    width: '50px', 
                    height: '50px', 
                    borderRadius: '50%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    zIndex: '50', 
                    cursor: 'pointer', 
                    backgroundColor: '#f7cc2a' }}
                    onClick={handleClose}
                    >
                    <Close />
                </div>
                <Modal.Body style={{ overflowY: 'scroll' }}>
                    <CGU />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalCGU;