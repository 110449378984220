import { combineReducers } from 'redux';
import compteReducers from './compteReducers';
import chatReducers from './chatReducers';
import ModalReducers from './ModalReducers';
import contentReducers from './contentReducer';
import paniersReducers from './paniersReducers';
import counterReducers from './counterReducers';

const rootReducer = combineReducers({
	compteReducers: compteReducers,
	chatReducers: chatReducers,
	modalReducers: ModalReducers,
	contentReducers: contentReducers,
	paniersReducers: paniersReducers,
	counterReducers: counterReducers,
});

export default rootReducer;
