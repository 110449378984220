import { api } from '../../../res/constants';
import WS from './api';
import {
	SET_ORDERS_COUNTER_ADD,
	SET_ORDERS_COUNTER_REMOVE,
	UPDATE_NEW_ITEMS_COUNT,
} from './actionType';

// Stripe
export const stripePaymentMethodHandler = async (data, cb) => {
	const { amount, client, result } = data;
	if (result?.error) {
		cb(result);
	} else {
		return new Promise(async (resolve, reject) => {
			console.log('stripePayment: ', client);
			const paymentResponse = await stripePayment(client);
			resolve(paymentResponse);
			cb(paymentResponse);
		});
	}
};

const stripePayment = async (data) => {
	return new Promise((resolve, reject) => {
		orderCreateAndPayPost(
			typeof data === 'string' ? data : null,
			data.firstName,
			data.lastName,
			data.adresse,
			data.zip_code,
			data.city,
			data.country,
			data.paymentType
		).then(
			(response) => {
				resolve(response);
			},
			(error) => {
				console.log(`Error Payment`, error);
				reject(error);
			}
		);
	});
};

// WS HAKA
export const ordersGet = (id) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `${api.orders}${id ? `/${id}` : ''}`,
		}).then(
			(res) => {
				resolve(res.data.data);
			},
			(error) => {
				console.log('ERROR ORDERS : ', error);
				reject(error);
			}
		);
	});
};

export const orderSales = (id) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `${api.sale}${id ? `/${id}` : ''}`,
		}).then(
			(res) => {
				resolve(res.data.data);
				// console.log(resolve);
			},
			(error) => {
				console.log('ERROR ORDERS : ', error);
				reject(error);
			}
		);
	});
};

export const orderWallet = (id) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `${api.balance}${id ? `/${id}` : ''}`,
		}).then(
			(res) => {
				resolve(res.data);
				// console.log(resolve);
			},
			(error) => {
				console.log('ERROR ORDERS : ', error);
				reject(error);
			}
		);
	});
};

export const ordersItemsGet = (id, data, label) => {
	const formData = new FormData();
	formData.append('trackingCode', data.trackingCode);
	formData.append('transporterName', data.transporterName);
	formData.append('trackingUrl', data.trackingUrl);
	var url_orders = 'accept';
	if (!label) {
		url_orders = 'decline';
	}
	if (label === 'confirmation') {
		url_orders = 'confirm';
	}
	return new Promise((resolve, reject) => {
		WS.axiosPutFormUrlEncoded(
			formData,
			`${api.orders_itemsAccept}/${id}/${url_orders}`
		).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERREUR ORDERS ITEMS', error);
				reject(error);
			}
		);
	});
};

export const confirmReception = (id) => {
	return new Promise((resolve, reject) => {
		WS.axiosPutWithToken(`${api.orders_items}/${id}/confirm`)
			.then(res => {
				resolve(res);
			}, (error) => {
				reject(error);
			});
	});
};

export const ordersAdressShipmentsGet = (getLastShipments) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `${
				getLastShipments ? api.orders + api.last_livraisons : api.livraisons
			}`,
		}).then(
			(res) => {
				resolve(res.data.data);
			},
			(error) => {
				console.log('ERROR ORDERS : ', error);
				reject(error);
			}
		);
	});
};

export const orderCreateAndPayPost = (
	adresse_id,
	firstName,
	lastName,
	adress,
	zip_code,
	city,
	country,
	paymentType
) => {
	const fd = new FormData();
	if (adresse_id) {
		fd.append('adresse_id', adresse_id);
	} else {
		fd.append('adresse.nom', lastName);
		fd.append('adresse.prenom', firstName);
		fd.append('adresse.adresse', adress);
		fd.append('adresse.codePostale', zip_code);
		fd.append('adresse.ville', city);
		fd.append('adresse.pays', country);
		fd.append('paymentType', paymentType)
	}

	return new Promise((resolve, reject) => {
		WS.axiosSecurePost(fd, api.orders).then(
			(response) => {
				resolve(response.data);
			},
			(error) => {
				reject(error);
			}
		);
	});
};

export const ordersStatusPut = (data, status) => {
	const formData = new FormData();
	if (status === 'accept') {
		formData.append('trackingCode', data.trackingCode);
		formData.append('transporterName', data.transporterName);
		formData.append('trackingUrl', data.trackingUrl);
	}

	return new Promise((resolve, reject) => {
		if (status === 'accept') {
			WS.axiosPutFormUrlEncoded(
				formData,
				`${api.orders_items}/${data.id}/${status}`
			).then(
				(res) => {
					resolve(res);
				},
				(error) => {
					reject(error);
				}
			);
		} else {
			WS.axiosPutWithToken(`${api.orders_items}/${data.id}/${status}`).then(
				(res) => {
					resolve(res);
				},
				(error) => {
					reject(error);
				}
			);
		}
	});
};

export const messageRequestPaid = (data) => {
	const formData = new FormData();
	formData.set('price', data.price);
	formData.set('client_id', data.client_id);
	formData.set('description', data.description);

	return new Promise((resolve, reject) => {
		WS.axiosSecurePost(formData, `${api.paniers}/create-private-product`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERREUR modification compte', error);
				reject(error);
			}
		);
	});
};

export const buyPrivateProduct = (data) => {
	const formData = new FormData();
	formData.append('panierId', data.panierId)
	formData.append('paymentType', data.paymentType)
	formData.append('messageId', data.messageId)

	return new Promise((resolve, reject) => {
		WS.axiosSecurePost(formData, `${api.orders}/buy-private-product`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				reject(error)
			}
		)
	})
}

export const requestTransfert = (data) => {
	console.log('-----', data);
	const dataToSend = {
		amount: data.values.amount,
		email: data.values.email,
		pseudo: data.values.pseudo,
		methodPayment: data.values.methodPayment
	}

	// console.log('Data to send', dataToSend);

	return new Promise((resolve, reject) => {
		WS.axiosSecurePostWithToken(api.transfert, dataToSend).then(
			(res) => {
				resolve(res)
			},
			(error) => {
				console.log('ERROR_TRANSFERT', error)
				reject(error)
			}
		)
	})
}


export const getTransfert = () => {
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${api.transfert}/user/is-exists`).then(
			(res) => {
				resolve(res)
			},
			(error) => {
				reject(error)
			}
		)
	})
}

export const updateTransfert = (idTransfers, data) => {
	const fd = new FormData();
	fd.append('amount', data.amount);
	fd.append('email', data.email);
	fd.append('pseudo', data.pseudo);

	return new Promise((resolve, reject) => {
		WS.axiosSecurePutWithToken(`${api.transfert}/${idTransfers}`, fd)
			.then((res) => {
				resolve(res)
			},
			(error) => {
				reject(error)
			})
	})
}

//Action
export const setOrdersCounterAdd = (data) => {
	return {
		type: SET_ORDERS_COUNTER_ADD,
		data,
	};
};
export const setOrdersCounterRemove = (data) => {
	return {
		type: SET_ORDERS_COUNTER_REMOVE,
		data,
	};
};
export const updateNewItemsCount = (data) => ({
  type: UPDATE_NEW_ITEMS_COUNT,
	data,
});