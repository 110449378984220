import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import CustomRouter from "./Router/router";
import { applyMiddleware, createStore } from "redux";
import { Provider, useSelector } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./services/stores/reducers";
import { useEffect, useRef } from "react";
import rabbitmq from "./utils/rabbitmq";
import { newMessage, refreshDiscussion } from "./services/stores/actions/chat";
import { newContent } from "./services/stores/actions/content";
import SocketClient from "./utils/socketjs";
import { composeWithDevTools } from "redux-devtools-extension";
import { getAllDicussions } from "./services/stores/actions/chat";

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem("state", JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem("state");
    return stateStr ? JSON.parse(stateStr) : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage();
export const store = createStore(
  rootReducer,
  persistedStore,
  composeWithDevTools(applyMiddleware(thunk))
); //applyMiddleware(thunk)

if (window.Cypress) {
  window.store = store;
  window.getAllDicussions = getAllDicussions;
}

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

function App() {
  const mainRef = useRef(null);
  const [hide, setHide] = useState(false);
  // useEffect(() => {
  // 	// const state = store.getState();
  // 	// const { userConnected, isConnected } = state.compteReducers;
  // 	// rabbitmq.connectAndReconnect();
  // 	// if (isConnected) {
  // 	// 	rabbitmq.subscribe(
  // 	// 		`/topic/server.new.message.${userConnected.id}`,
  // 	// 		(msg) => {
  // 	// 			store.dispatch(newMessage(JSON.parse(msg.body)));
  // 	// 		}
  // 	// 	);
  // 	// 	rabbitmq.subscribe(
  // 	// 		`/topic/server.new.content.${userConnected.id}`,
  // 	// 		(content) => {
  // 	// 			store.dispatch(newContent({ contents: JSON.parse(content.body) }));
  // 	// 		}
  // 	// 	);
  // 	// 	rabbitmq.subscribe(
  // 	// 		`/topic/server.new.comment.${userConnected.id}`,
  // 	// 		(data) => {
  // 	// 			'new comment';
  // 	// 		}
  // 	// 	);
  // 	// 	rabbitmq.subscribe(
  // 	// 		`/topic/server.private.order.${userConnected.id}`,
  // 	// 		(data) => {
  // 	// 			// console.log('Response rabbitmq server : ', data);
  // 	// 			store.dispatch(refreshDiscussion(true));
  // 	// 		}
  // 	// 	);
  // 	// }

  // }, []);

  const handlerKeyDown = (event) => {
    if (event.key === "Alt" || event.key === "Meta") {
      setHide(true);
      setTimeout(() => {
        setHide(false);
      }, 5000);
    }
    // if (event.ctrlKey && (event.key === "s" || event.key === "S")) {
    //   event.preventDefault();
    // }
    // if (
    //   event.ctrlKey &&
    //   event.shiftKey &&
    //   (event.key === "i" || event.key === "I")
    // ) {
    //   event.preventDefault();
    // }
    // if (event.key === "F12") {
    //   event.preventDefault();
    // }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlerKeyDown);
    return () => {
      window.removeEventListener("keydown", handlerKeyDown);
    };
  }, []);

  return (
    <Provider store={store}>
      {hide && <div className="makeblur"></div>}
      <CustomRouter></CustomRouter>
      <ToastContainer limit={1} />
    </Provider>
  );
}

export default App;
