import {
	SET_PANIERS_COUNTER_ADD,
	SET_PANIERS_COUNTER_REMOVE,
	SET_PANIERS_COUNTER_RESET,
	SET_PANIERS_COUNTER,
} from '../actions/actionType';

let initialState = {
	paniersCount: 0,
};

const paniersReducers = (state = initialState, action) => {
	const { paniersCount } = state;
	switch (action.type) {
		case SET_PANIERS_COUNTER_ADD:
			return {
				...state,
				paniersCount: paniersCount + 1,
			};
		case SET_PANIERS_COUNTER_REMOVE:
			return {
				...state,
				paniersCount: paniersCount - 1,
			};
		case SET_PANIERS_COUNTER_RESET:
			return {
				...state,
				paniersCount: 0,
			};
		case SET_PANIERS_COUNTER:
			return {
				...state,
				paniersCount: action.data.paniersCount,
			};

		default:
			return state;
	}
};

export default paniersReducers;
