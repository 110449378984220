import { CONTENT_RECEIVED, CONTENT_SIGNAL_ID } from '../actions/actionType';

const initialState = {
	contents: {},
	content_signal_id: null,
};

const contentReducers = (state = initialState, action) => {
	switch (action.type) {
		case CONTENT_RECEIVED:
			return {
				...state,
				contents: action.data.contents,
			};
		case CONTENT_SIGNAL_ID:
			return {
				...state,
				content_signal_id: action.data.contents,
			};
		default:
			return state;
	}
};

export default contentReducers;
