import Stomp from 'stompjs';
import { RabbitmqConfig } from '../res/constants';

class _RabbitmqClient {
	ws = {};
	client = {};
	isConnected = false;
	callback = [];
	sendCallback = [];

	constructor() {
		this.on_connect.bind(this);
		this.on_error.bind(this);
		this.send.bind(this);
		this.subscribe.bind(this);
		this.getClient.bind(this);
		this.connectAndReconnect.bind(this);
	}

	on_connect(frame) {
		this.isConnected = true;
		for (const cb of this.callback) {
			cb();
		}
		for (const cb of this.sendCallback) {
			cb();
		}
		this.sendCallback = [];
	}

	on_error() {
		this.isConnected = false;
		this.connectAndReconnect();
	}

	send(topic, body) {
		if (this.isConnected) {
			// console.log(`IF send`, topic);
			this.client.send(topic, {}, JSON.stringify(body));
		} else {
			// console.log(`ELSE send`, topic);
		}
	}
	
	sendAndWait(topic, body) {
		if (this.isConnected) {
			// console.log(`IF send`, topic);
			this.client.send(topic, {}, JSON.stringify(body));
		} else {
			this.sendCallback.push(() => {
				// console.log(`ELSE send`, topic);
				this.client.send(topic, {}, JSON.stringify(body));
			});
		}
	}

	subscribe(topic, cb) {
		if (!this.isConnected) {
			this.callback.push(() => {
				// console.log(`Rabbit`, topic);
				this.client.subscribe(topic, cb);
			});
			return false;
		} else {
			// console.log(`else Rabbit`, topic);
			return this.client.subscribe(topic, cb);
		}
	}

	unsubscribe(subIds) {
		subIds.forEach((e) => {
			if (e) {
				e.unsubscribe();
			}
		});
	}

	getClient() {
		return this.client;
	}

	connectAndReconnect() {
		this.ws = new WebSocket(RabbitmqConfig.url);
		this.client = Stomp.over(this.ws);
		this.client.connect(
			RabbitmqConfig.username,
			RabbitmqConfig.password,
			(frame) => this.on_connect(frame),
			() => this.on_error(),
			RabbitmqConfig.vhost
		);
	}
}

const RabbitmqClient = new _RabbitmqClient();

export default RabbitmqClient;
