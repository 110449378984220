/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Modal } from "react-bootstrap";
import CGU from "../../views/CGU/cgu";
import Close from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";

const ModalConditionExplicitContent = (props) => {
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const dispatch = useDispatch();
  const handleSelect = (index) => {
    if (index === 1) {
      setShow(false);
      dispatch({ type: "ACCEPT_CONDITION", data: true });
    } else if (index === 2) {
      setShow(false);
      setShow2(true);
    } else if (index === 3) {
      setShow(false);
      setShow2(false);
      setTimeout(() => {
        setShow(true);
      }, 5000);
    } else if (index === 4) {
      setShow2(false);
      dispatch({ type: "ACCEPT_CONDITION", data: true });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 3000);
  }, []);

  return (
    showContent && (
      <>
        <Modal
          style={{ padding: "0px 25px" }}
          show={show}
          onHide={() => handleSelect(3)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onClick={() => {
            console.log("I click");
          }}
        >
          <Modal.Body>
            Pour accéder à ce contenu, veuillez confirmer que vous avez plus de
            18 ans.
          </Modal.Body>
          <Modal.Footer>
            <a
              className="btn btn-light rounded-pill"
              onClick={() => handleSelect(1)}
            >
              Oui, j'ai plus de 18 ans
            </a>
            <a
              className="btn btn-light btn-yellow rounded-pill"
              onClick={() => handleSelect(2)}
            >
              lien CGU
            </a>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show2}
          size="lg"
          onHide={() => handleSelect(4)}
          aria-labelledby="contained-modal-litle-vcenter"
          centered
        >
          <div
            style={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "50",
              cursor: "pointer",
              backgroundColor: "#f7cc2a",
            }}
            onClick={() => handleSelect(4)}
          >
            <Close />
          </div>
          <Modal.Body style={{ overflowY: "scroll" }}>
            <CGU />
          </Modal.Body>
        </Modal>
      </>
    )
  );
};

export default ModalConditionExplicitContent;
