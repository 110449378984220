import React, { Component } from "react";
import "./MessagesDetails.css";
// material icons
import ImageIcon from "@material-ui/icons/Image";
import InfoBtn from "../../assets/info.png";
import close from "../../assets/close.png";
import user_add from "../../assets/profil/user_add.svg";
// import ProgressBar from 'react-progress-bar';
import {
  Popover,
  OverlayTrigger,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import ic_search from "../../assets/ic_search.svg";
import musicDefaultPreview from "../../assets/musicDefaultPreview.svg";
import points from "../../assets/profil/points.svg";
import signaler from "../../assets/profil/signaler.png";
import bloquer from "../../assets/profil/bloquer.png";
import defaultProfile from "../../assets/profil/utilisateur.png";
import Message from "../../components/Message/Message";
import { Link } from "react-router-dom";
import StickyFooter from "../../components/Footer/StickyFooter/StickyFooter";
import Select from "react-dropdown-select";
import {
  checkIfFriend,
  getAllNewRequestFriend,
  searchUser,
  searchUserById,
  searchUserByPseudo,
  sendNewRequestFriend,
  userGetListBlocker,
} from "../../services/stores/actions/compte";
import {
  getAllDicussions,
  getAllMessagesFor,
  newMessage,
  setSelectedDiscussion,
  showAllDiscussion,
  showMessages,
  setUpdateDiscussion,
  refreshDiscussion,
  createNewDiscussion,
} from "../../services/stores/actions/chat";
import { FileUploader } from "react-drag-drop-files";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { InfiniteScrolling } from "../../components/InfiniteScroll/InfiniteScroll";
import InfiniteScroll from "react-infinite-scroll-component";
import { ModalDeleteMessage } from "../../components/Modal/ModalMessage";
import { SwipeAction, TrailingActions } from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { momentGetDiff, timeDiff } from "../../services/stores/actions/size";
import WS from "../../services/stores/actions/api";
import { api, attributesAll } from "../../res/constants";
import { ModalPaid } from "../../components/Modal/ModalPaid";
import Followup from "../../components/Followup/Followup";
import SocketClient from "../../utils/socketjs";
import { getCounter } from "../../services/stores/actions/recentActivities";
import {
  setCouterMessage,
  setTotalCounter,
} from "../../services/stores/actions/recentActivities";
import { BiSend } from "react-icons/bi";
import { convert64IntoFile } from "../../utils/encryption";
import { FaCamera } from "react-icons/fa";
import CameraComponent from "../../components/Webcam/webcam";
import { statusGenerator } from "../../services/stores/actions/services";
import { attributes } from "@syncfusion/ej2-base";
import ModalGroupDiscussion from "../../components/Modal/ModalGroupDiscussion";

export class MessagesDetails extends Component {
  constructor(props) {
    super(props);
    this.myRefWeb = React.createRef();
    this.myRefMobile = React.createRef();
    this.state = {
      newMessage: "",
      date: new Date(),
      messageList: [],
      selectedMessage: false,
      openNewMessage: false,
      isBlocked: false,
      isBlocker: false,
      isLoading: false,
      newMessageUser: "",
      newReceivers: [],
      users: [],
      allUsers: [],
      listRequestFriends: [],
      friends: [],
      allDiscussions: [],
      listBlocked: [],
      searchInput: "",
      resultSearchUser: [],
      messageSorted: [],
      selectDiscussion: [],
      inputList: [],
      updateDiscussion: false,
      picture: null,
      errors: null,
      listFile: [],
      items: [],
      images: [],
      hasMore: true,
      currentPage: 1,
      paymentintent: "",
      isWeb: true,
      isLoadingMessage: false,
      page: 1,
      loadFetch: false,

      isNewMessage: false,
      isNewUser: false,
      newUserSearch: {},

      showCamera: false,
      status: [],
      userToTalkWith: "",

      userListBlocked: [],
      userListBlock: [],
      showModalGroup: false,
    };
    this.inputRefs = React.createRef();
    this.modalRefs = React.createRef();
    this.submitMessage = this.submitMessage.bind(this);
  }

  static defaultProps = {
    messageData: {
      id: null,
      name: "",
      content: [],
      files: [],
      followed: null,
      status: "",
      img: defaultProfile,
    },
  };

  findUserStatus(userId) {
    if (
      userId !== "643eab1e367e10ae448d88b3" &&
      userId !== "64ae91be58325ac3a33c1c5b"
    ) {
      checkIfFriend(userId).then((request) => {
        const userStatus = statusGenerator({
          requestDetails: request,
          userId,
        });
        this.setState({
          status: userStatus,
        });
      });
    }
  }

  componentDidMount() {
    const userId = this.props.discussion?.discussion?.members.find(
      (user) => user !== this.props.user.id
    );

    const queryParams = new URLSearchParams(this.props.history.location.search);
    const ui = queryParams.get("ui");
    const id = queryParams.get("user");
    const idu = queryParams.get("idu");
    const idDiscussion = queryParams.get("id");
    if (!idDiscussion) {
      let discussionSelected = this.props.discussions?.find((discussion) => {
        return (
          discussion.discussion.members.length === 2 &&
          discussion.discussion.members.includes(idu || id)
        );
      });

      this.props.setSelectedDiscussion(discussionSelected);
      if (discussionSelected) {
        this.props.setSelectedDiscussion(discussionSelected);
      }
    } else {
      let discussionSelected = this.props.discussions?.find(
        (discussion) => discussion.discussion?.id === idDiscussion
      );
      if (discussionSelected)
        this.props.setSelectedDiscussion(discussionSelected);
    }

    if (userId) {
      if (this.props.discussion?.discussion?.members.length < 2) {
        this.findUserStatus(userId);
      }
      if (ui) {
        this.findUserStatus(ui);
        this.setState({ userToTalkWith: ui });
      }
      if (id) {
        if (!id.split(",")[1]) this.findUserStatus(id);
        this.setState({ userToTalkWith: id });
      }
      if (idu) {
        this.findUserStatus(idu);
        this.setState({ userToTalkWith: idu, isNewUser: true });
      }
    } else {
      if (idu) {
        this.findUserStatus(idu);
        this.setState({ userToTalkWith: idu, isNewUser: true });
      }
    }

    this.setState({
      isLoadingMessage: this.props.history.location.isLoadingMessage,
    });
    this.setState({ updateDiscussion: this.props.updateDiscussion });

    searchUser(null, 100).then((res) => {
      this.setState({ users: res.data.data.content });
    });

    // --------------------
    let urlType = this.props.match.params.id;
    // HERE GOES EVERY PROCESSUS
    this.getAllDiscussions(urlType, this.props.selectedDiscussion);
    this.getAllMessages(this.props.selectedDiscussion);
  }

  setPI(values) {
    this.submitMessage(JSON.stringify(values));
  }

  async checkIfDiscussionGroupExist(idReceiver) {
    WS.axiosGetWithToken({
      endpoint: `/discussions/check-group-users?userIds=${idReceiver}`,
    }).then(
      (res) => {
        this.setState({ isLoadingMessage: false });
        if (res.data?.data?.discussion) {
          this.getAllMessages(res.data.data);
        } else {
          // CREER UNE DISCUSSION
          this.setState({ isNewMessage: true });
          const Subject = res.data.data.subject.map((rep) => ({
            pseudo: rep.pseudo,
          }));
          Subject.push({ pseudo: this.props.user.pseudo });

          if (!idReceiver.split(",")[1]) this.findUserStatus(idReceiver);

          this.setState({
            newUserSearch: {
              pseudo: Subject.map((item) => item.pseudo).join(", "),
            },
          });

          // this.openNewMessage();
          const e = [];
          res.data.data.subject.forEach((element) => {
            e.push({
              label: element.pseudo,
              pseudo: element.pseudo,
              value: element.id,
            });
          });

          this.setState({ newReceivers: e });
          // this.props.setSelectedDiscussion(null);
          // this.setState({ allDiscussions: null })
        }
      },
      (error) => {}
    );
  }

  async checkIfDiscussionExist(idReceiver) {
    // this.setState({ isLoadingMessage: true })
    WS.axiosGetWithToken({
      endpoint: `${api.discussionByUserId}/${idReceiver}`,
    }).then(
      (res) => {
        this.setState({ isLoadingMessage: false });
        if (res.data.data.discussion) {
          this.getAllMessages(res.data.data);
        } else {
          // CREER UNE DISCUSSION IF SUBMIT BUTTON
          searchUserById(idReceiver).then((rep) => {
            this.setState({
              newUserSearch: {
                pseudo: rep.data.pseudo,
                profil: rep.data.profil,
                id: idReceiver,
              },

              userToTalkWith: idReceiver,
            });

            this.findUserStatus(idReceiver);
          });

          this.setState({ isNewMessage: true });
          this.openNewMessage();
          const e = [
            {
              label: res.data.data.user?.pseudo || res.data.data.subject.pseudo,
              pseudo:
                res.data.data.user?.pseudo || res.data.data.subject.pseudo,
              value: res.data.data.user?.id || res.data.data.subject.id,
            },
          ];
          this.setState({ newReceivers: e });
          this.props.setSelectedDiscussion(null);
          this.setState({ allDiscussions: null });
        }
      },
      (error) => {
        // console.log("discussion not exist: ", error);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.updateDiscussion !== this.props.updateDiscussion) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    // this.props.setSelectedDiscussion(null);
  }

  // SwipeableList , on swipe right
  trailingActions = (discussionId) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => {
          // problem render all time from listing discussion
          // this.modalRefs.current();
        }}
      >
        <ModalDeleteMessage
          label={`Delete discussion`}
          modalRefs={this.modalRefs}
          child={
            <button className="btn btn-danger btn-rounded k-regular-15">
              Delete
            </button>
          }
          discussionId={discussionId}
          cdm={() => this.componentDidMount()}
        />
      </SwipeAction>
    </TrailingActions>
  );

  // Create New discussion so search user
  searchUser = (pseudo) => {
    searchUserByPseudo(pseudo).then((response) => {
      this.setState({
        friends: response.data.data.content[0],
      });
    });
  };

  handleChange = (event) => {
    this.setState({ newMessageUser: event.target.value });
  };

  // Comment
  handleChangeCommentInput = (event) => {
    this.setState({ newMessage: event.target.value });
  };

  // Open new dialog message
  openDialogMessage = () => {
    if (this.state.newMessageUser.length) {
      let newUser = this.state.newMessageUser;
      return this.setState({ newMessageUser: newUser });
    }
  };

  // Get all discussion from props redux
  getAllDiscussions = function (urlType, selectedDiscussion) {
    getAllDicussions()
      .then((data) => {
        this.props.showAllDiscussions(data.data.content);
        this.props.showMessages([]);
        this.setState({ allDiscussions: data.data.content });

        const query = new URLSearchParams(this.props.location.search);

        if (urlType === "discussion") {
          const idDiscussion = query.get("id");
          const idAllUser = query.get("user");
          const verifIdUser = idAllUser.split(",").length;

          getAllMessagesFor(idDiscussion)
            .then((rep) => {
              if (rep.data.content) {
                if (verifIdUser === 1) {
                  WS.axiosGetWithToken({
                    endpoint: `${api.discussionByUserId}/${idAllUser}`,
                  }).then((res) => {
                    if (res.data.data) this.getAllMessages(selectedDiscussion);
                  });
                } else if (verifIdUser > 1) {
                  WS.axiosGetWithToken({
                    endpoint: `/discussions/check-group-users?userIds=${idAllUser}`,
                  }).then((res) => {
                    // this.props.setSelectedDiscussion(res.data.data);
                    this.getAllMessages(res.data.data);
                  });
                }
              }
            })
            .catch((err) => {
              // console.log("ERROR : discussion not found");
            });
        } else if (urlType === "new") {
          if (query.get("idu")) {
            this.checkIfDiscussionExist(query.get("idu"));
          }
        } else if (urlType === "new-group") {
          if (query.get("idus")) {
            this.checkIfDiscussionGroupExist(query.get("idus"));
          }
        } else if (urlType === "admin") {
          const idModerator = api.moderateur_id;
          this.checkIfDiscussionExist(idModerator);
        } else {
          this.checkIfDiscussionExist(urlType);
        }
      })
      .catch((_err) => {});

    getAllNewRequestFriend().then((res) => {
      this.setState({ listRequestFriends: res.data.data });
    });
  };

  // fetchMoreData = () => {
  // 	if (this.props.messages.length >= 500) {
  // 		this.setState({ hasMore: false });
  // 		return;
  // 	}
  // 	setTimeout(() => {
  // 		this.setState({ items: this.state.items.concat(Array.from({ length: 20 })), });
  // 	}, 1500);
  // };

  fetchMoreData2 = () => {
    // this.setState({items: [...this.state.items, ...[11,12,13,14,15,16,17,18,19,20]]})
    const discussionId = this.props.discussion?.discussion?.id;
    const nextPage = this.state.page + 1;
    getAllMessagesFor(discussionId, nextPage)
      .then((data) => {
        // Convert the data into an array of messages
        const newMessages = data.data.content.map((message) => {
          return {
            id: message.id,
            img: message.sender.profile
              ? message.sender.profil
              : defaultProfile,
            content: message.message,
            files: message.files,
            isSentByCurrentUser:
              this.props.userConnected &&
              message.sender.id === this.props.userConnected.id,
            time: message.createdAt,
          };
        });
        const updatedMessages = [...newMessages, ...this.props.messages];
        this.props.showMessages(updatedMessages);
        this.scrollToLastMsg();
        this.setState({ page: nextPage });

        if (updatedMessages.length === this.props.messages.length) {
          this.setState({ hasMore: false, loadFetch: false });
        } else {
          this.setState({ loadFetch: false });
        }
      })
      .catch((error) => {
        // console.log("Error fetching more messages:", error);
      });
  };

  getAllMessages = (discussion) => {
    if (discussion) {
      return new Promise((resolve, reject) => {
        getAllMessagesFor(discussion?.discussion?.id)
          .then((msgs) => {
            this.getBlocked({
              listUserBlocked: msgs.data?.blockedUsers,
              discussion,
            });
            const formattedMsgs = msgs.data.content.map((msg) => ({
              id: msg.id,
              img: msg?.sender?.profil ? msg?.sender?.profil : defaultProfile,
              time: msg.createdAt,
              content: msg.message,
              isSentByCurrentUser: msg.sender.id === this.props.user.id,
              files: msg.files,
            }));
            this.setState({ isLoadingMessage: false });
            this.props.showMessages(formattedMsgs);
            this.setState({ openNewMessage: false });
            // this.props.setSelectedDiscussion(discussion);
            this.scrollToLastMsg();

            getCounter().then((res) => {
              this.props.setCouterMessage(res.data.data.message);
              // this.props.updateNewItemsCount(res.data.data.orders);
              let total =
                parseInt(res.data.data.notification) +
                parseInt(res.data.data.message) +
                parseInt(res.data.data.orders) +
                parseInt(res.data.data.friend);
              this.props.setTotalCounter(total);
            });
            resolve();
          })
          .catch((_err) => {
            reject(_err);
          });
      });
    } else {
      return null;
    }
  };

  RefreshListDiscussion = (discu, newMessage, isNewDiscussion) => {
    this.scrollToLastMsg();
    getAllMessagesFor(discu?.discussion?.id)
      .then((msgs) => {
        this.props.setSelectedDiscussion(discu);
        this.setState({ isLoadingMessage: false });
        // if (!isNewDiscussion) this.answerMessageSocket(newMessage);

        getCounter().then((res) => {
          this.props.setCouterMessage(res.data.data.message);
          let total =
            parseInt(res.data.data.notification) +
            parseInt(res.data.data.message) +
            parseInt(res.data.data.orders) +
            parseInt(res.data.data.friend);
          this.props.setTotalCounter(total);
        });

        setTimeout(() => {
          getAllDicussions().then((data) => {
            this.props.showAllDiscussions(data.data.content);
            this.scrollToLastMsg();
            this.setState({ allDiscussions: data.data.content });
          });
        }, 1500);
      })
      .catch((_err) => {});
  };

  selectDiscussionOnMobile = () => {
    if (window.innerWidth < 767) {
      this.setState({ isWeb: false });
    }
  };

  // Signal , Block user, delete discussion
  onRenderPopOver = (image, taille, pseudo, userId, label, discussionId) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          {label === "signal" ? (
            <div className="d-flex flex-column">
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={{
                  pathname: `/formSignaler/${pseudo}`,
                  state: { id: userId, origin: `user`, contentId: userId },
                }}
              >
                <img src={signaler} alt="" /> Report
              </Link>

              {!this.state.isBlocked &&
                !this.state.isBlocker &&
                this.props.selectedDiscussion.discussion.members.length <=
                  2 && (
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to={{
                      pathname: `/formBloquerInDiscussion/${discussionId}`,
                      state: {
                        id: userId,
                        reason: `${"bloquerInDisc"}`,
                      },
                    }}
                  >
                    <img src={bloquer} alt="" /> Block
                  </Link>
                )}
            </div>
          ) : (
            <div className="d-flex flex-column">
              {/* <ModalDeleteMessage
								label={`Delete chat`}
								discussionId={discussionId?.discussion?.id}
								cdm={() => this.componentDidMount()}
							/> */}
            </div>
          )}
        </Popover.Content>
      </Popover>
    );

    return (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose
      >
        <img
          src={image}
          alt=""
          style={{ width: taille + "px", height: taille + "px" }}
          className="ml-1"
        />
      </OverlayTrigger>
    );
  };

  selectMessage = () => {
    this.setState({ selectedMessage: !this.state.selectedMessage });
  };

  // Tag User
  customItemRenderer = ({ item, itemIndex, props, state, methods }) => (
    <div className="p-2">
      <div onClick={() => methods.addItem(item)}>
        <img src={item.avatar} alt="" className="mr-2" />
        {item.label}
      </div>
    </div>
  );

  //  Result search user web
  options = () => {
    return this.state.users.map((user) => ({
      label: `${user.pseudo}`,
      pseudo: `${user.pseudo}`,
      value: user.id,
    }));
  };

  // Result search user mobile version
  optionsMobile = () => {
    return this.state.searchInput.map((user) => ({
      label: `${user.pseudo}`,
      pseudo: `${user.pseudo}`,
      value: user.id,
    }));
  };

  // Select user for mobile
  optionsSelectMobile = () => {
    return this.state.messageSorted.map((user) => ({
      label: `${user.user.firstName} ${user.user.lastName}`,
      pseudo: `${user.user.pseudo}`,
      value: user.discussion.id,
      lastMessage: user.discussion.lastMessage.message,
      selectedDiscussion: user.discussion,
    }));
  };

  closeCamera() {
    this.setState({ showCamera: "" });
  }

  closeModalGroup() {
    this.setState({ showModalGroup: false });
  }

  render() {
    return (
      <div className="container-fluid h-100" id="mb_details_message">
        {this.state.showCamera && (
          <CameraComponent
            closeCam={this.closeCamera.bind(this)}
            saveMedias={this.handleUploadScreenFile.bind(this)}
          />
        )}
        {this.state.showModalGroup && (
          <ModalGroupDiscussion
            listUserBlocked={this.state.userListBlocked}
            handleClose={this.closeModalGroup.bind(this)}
            show={this.state.showModalGroup}
            history={this.props.history}
            isWeb={false}
          />
        )}
        <div className="row h-100">
          {/* <div className="col-lg-2 d-none d-lg-block px-3">
            <div style={{ position: "absolute", bottom: 0 }}>
              <StickyFooter />
            </div>
          </div> */}
          <div className="col-lg-8 col-md-12 h-100">
            <div className="MessagesContainer h-100">
              {/* <div className="d-flex flex-md-row mx-0"> */}
              {/*  Right Message input */}
              <div className="col-md-7 p-0 p-md-3 discussions-details h-100">
                {this.renderDiscussionMessage(
                  this.fomatDicussionForOpenMessage()
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Opening Existing discussion
  fomatDicussionForOpenMessage = () => {
    if (this.props.selectedDiscussion) {
      return {
        id: this.props.selectedDiscussion.discussion?.id,
        idUser:
          this.props.selectedDiscussion.user?.id ||
          this.props.selectedDiscussion.subject?.id,
        pseudo:
          this.props.selectedDiscussion.user?.pseudo ||
          this.props.selectedDiscussion.subject?.pseudo,
        profil:
          this.props.selectedDiscussion.user?.profil ||
          this.props.selectedDiscussion.subject?.profil,
        name: `${this.props.selectedDiscussion.user?.pseudo} `,
        content: this.props.selectedDiscussion.discussion?.lastMessage?.message,
        followed:
          this.props.selectedDiscussion.friend_request &&
          this.props.selectedDiscussion.friend_request.status === "VALIDATED",
      };
    } else {
      return {
        id: null,
        idUser: this.state.newReceivers.id,
        pseudo: this.state.newReceivers.pseudo,
        profil: null,
        name: this.state.newReceivers.label,
      };
    }
  };

  // Select existing discussion
  onRenderSearchForm2 = () => {
    return (
      <div className="inputSearch">
        <InputGroup
          className="mb-3"
          style={{
            borderRadius: "125rem",
            border: "solid 1px #C4C4C6",
          }}
        >
          <div className="new-user-wapper w-100 k-regular-15">
            <Select
              placeholder="Search"
              className="k-regular-15"
              style={{
                border: "0",
                borderRadius: "0rem 25rem 25rem 0",
              }}
              onChange={(e) => {
                this.openDialogMessage();
                this.getAllMessages(e[0].discussion);
                this.setState({ openNewMessage: false });
                // this.props.setSelectedDiscussion(e[0].discussion);
              }}
              options={this.optionsWeb()}
            />
          </div>
        </InputGroup>
      </div>
    );
  };

  // select user web in searchbar
  optionsWeb = () => {
    return (
      this.props.discussions &&
      this.props.discussions.map((discussion) => ({
        label: `${discussion.user.firstName} ${discussion.user.lastName}`,
        pseudo: `${discussion.user.pseudo}`,
        value: discussion.discussion.id,
        discussion: discussion,
      }))
    );
  };

  // Format each discussion
  formatDiscussion = (discussionDTO) => ({
    id: discussionDTO.discussion.id,
    idUser: discussionDTO.user.id,
    img: discussionDTO.user.profil || defaultProfile,
    name: `${discussionDTO.user.firstName} ${discussionDTO.user.lastName}`,
    followed:
      discussionDTO.friend_request &&
      discussionDTO.friend_request.status === "VALIDATED",
    content: discussionDTO.discussion.lastMessage.message,
    status: timeDiff(discussionDTO.discussion.lastMessage.createdAt), // momentGetDiff(discussionDTO.discussion.lastMessage.createdAt),
    userPseudo: discussionDTO.user.pseudo,
    friend_request: discussionDTO.friend_request,
    receiverMessage: discussionDTO.discussion.receiver,
    item: discussionDTO,
    isSeen: discussionDTO.isSeen,
    unreadMessage: discussionDTO.notSeenMessages,
  });

  contentPrivate = (contentData) => {
    return contentData?.includes('"data_pi":')
      ? "private content"
      : contentData?.substring(0, 10);
  };

  getButton() {
    const sendFriendRequest = (event) => {
      event.preventDefault();
      sendNewRequestFriend(this.state.userToTalkWith).then(() => {
        this.findUserStatus(this.state.userToTalkWith);
      });
    };

    if (
      this.state?.status &&
      this.state?.status.includes(attributesAll.nothing)
    ) {
      return (
        <button
          className="btn btn-yellow btn-rounded k-regular-13 ml-1"
          onClick={sendFriendRequest}
        >
          <img src={user_add} alt="" /> Follow
        </button>
      );
    }
    if (
      this.state?.status &&
      this.state?.status.includes(attributesAll.following) &&
      !this.state?.status.includes(attributesAll.waiting)
    ) {
      return (
        <button
          className="btn btn-yellow btn-rounded k-regular-13 ml-1"
          onClick={sendFriendRequest}
        >
          Follow back
        </button>
      );
    }
    return <></>;
  }

  getBlocked({ listUserBlocked, discussion }) {
    if (listUserBlocked.length) {
      const userSelectedDiscussion = this.props.selectedDiscussion?.user?.id;
      const usersBlocked = listUserBlocked.filter((blockedModel) => {
        return blockedModel.owner === this.props.user?.id;
      });

      const usersBlockedMe = listUserBlocked.filter((blockedModel) => {
        return blockedModel.user === this.props.user?.id;
      });

      this.setState({
        userListBlocked: usersBlocked,
        userListBlock: usersBlockedMe,
      });
      if (discussion?.discussion.members.length > 2) {
        this.setState({
          showModalGroup: usersBlocked.length ? true : false,
          isBlocked: false,
          isBlocker: false,
        });
      } else {
        const isBlocked = usersBlocked.some((blockedModel) => {
          return blockedModel.user === userSelectedDiscussion;
        });
        const isBlocker = usersBlockedMe.some((blockedModel) => {
          return blockedModel.owner === userSelectedDiscussion;
        });
        this.setState({ isBlocked, isBlocker });
      }
    } else {
      this.setState({ isBlocked: false, isBlocker: false });
    }
  }

  // Main Discussion
  renderDiscussionMessage = ({
    id,
    idUser,
    name = null,
    content,
    followed,
    pseudo,
    profil = defaultProfile,
    isOnline,
  }) => {
    if (this.props.refreshDiscussion) {
      this.getAllMessages(this.props?.selectedDiscussion);
      this.props.setRefreshDiscussion(false);
    }
    return (
      <div className="renderDiscussionMessage mt-1">
        <div
          className="row mx-0 discussion-header px-0 pt-1 container-fluid"
          style={{
            borderBottom: "solid 1px #ced4da",
            padding: "5px",
          }}
        >
          <div className="col pl-2">
            {this.props?.selectedDiscussion ? (
              <div className="row mx-0 pointer-cursor">
                <>
                  {!this.state.isBlocker &&
                  this.props.selectedDiscussion &&
                  this.props.selectedDiscussion.discussion.members.length <=
                    2 ? (
                    <Link
                      style={{
                        decoration: "none",
                        color: "black",
                        margin: "0 10px",
                      }}
                      to={{
                        pathname: `/details-user/${pseudo ? pseudo : this.state.newUserSearch.pseudo}`,
                        state: {
                          id: idUser,
                        },
                      }}
                    >
                      <img
                        src={profil ? profil : defaultProfile}
                        alt=""
                        className="img-fluid rounded-circle rounded_35"
                      />
                    </Link>
                  ) : (
                      this.state.isNewUser ? <p>{ profil}</p> : 
                    <img
                      src={profil ? profil : defaultProfile }
                      alt=""
                      className="img-fluid rounded-circle rounded_35"
                    />
                  )}
                </>
                <div className="mx-2">
                  <div className="d-flex align-items-center">
                    {!this.state.isBlocker &&
                    this.props.selectedDiscussion &&
                    this.props.selectedDiscussion?.discussion?.members?.length <=
                      2 ? (
                      <Link
                        style={{ decoration: "none", color: "black" }}
                        to={{
                          pathname: `/details-user/${pseudo}`,
                          state: {
                            id: idUser,
                          },
                        }}
                      >
                        <p className="k-semibold-13 m-0 p-0">
                          {this.props.selectedDiscussion?.user?.id ||
                          this.props.selectedDiscussion?.subject?.id
                            ? this.props.selectedDiscussion?.user?.pseudo ||
                              this.props.selectedDiscussion?.subject?.pseudo
                            : this.props.selectedDiscussion?.discussion.subject}
                        </p>
                      </Link>
                    ) : (
                      <p className="k-semibold-13 m-0 p-0">
                        {this.props.selectedDiscussion?.user?.id ||
                        this.props.selectedDiscussion?.subject?.id
                          ? this.props.selectedDiscussion?.user?.pseudo ||
                            this.props.selectedDiscussion?.subject?.pseudo
                          : this.props.selectedDiscussion?.discussion.subject}
                      </p>
                    )}
                    {this.state.status &&
                      this.state.status.includes(attributesAll.following) && (
                        <span className="pl-2 text-muted k-regular-11">
                          ✔ Followers
                        </span>
                      )}
                  </div>

                  <p className="m-0 p-0 k-regular-11 text-secondary">
                    {isOnline ? "Online" : "Offline"}
                  </p>
                </div>
              </div>
            ) : (
              <div className="row mx-0 pointer-cursor">
                <div className="row mx-0 pointer-cursor">
                  <div>
                    {!this.state.isBlocker &&
                    ((this.props.selectedDiscussion &&
                    this.props.selectedDiscussion.discussion.members.length <=
                      2) || (this.state.isNewUser && this.state.newUserSearch.pseudo)) ? (
                      <Link
                        style={{
                          decoration: "none",
                          color: "black",
                          margin: "0 10px",
                        }}
                        to={{
                          pathname: `/details-user/${this.state.newUserSearch?.pseudo}`,
                          state: {
                            id: idUser,
                          },
                        }}
                      >
                        <img
                          src={
                            this.state.newUserSearch?.profil || defaultProfile
                          }
                          alt=""
                          className="img-fluid rounded-circle rounded_35"
                        />
                      </Link>
                    ) : (
                      <img
                        src={this.state.newUserSearch?.profil || defaultProfile}
                        alt=""
                        className="img-fluid rounded-circle rounded_35"
                      />
                    )}
                  </div>
                  <div className="mx-1">
                    <div className="d-flex align-items-center">
                      {this.props.selectedDiscussion &&
                      this.props.selectedDiscussion?.discussion?.members
                        .length <= 2 ? (
                        <Link
                          style={{ decoration: "none", color: "black" }}
                          to={{
                            pathname: `/details-user/${this.state.newUserSearch.pseudo}`,
                            state: {
                              id: idUser,
                            },
                          }}
                        >
                          <p className="k-semibold-13 m-0 p-0">
                            {this.state.newUserSearch.pseudo}
                          </p>
                        </Link>
                      ) : (
                        <p className="k-semibold-13 m-0 p-0">
                          {this.state.newUserSearch.pseudo}
                        </p>
                      )}
                      {this.state.status &&
                        this.state.status.includes(attributesAll.following) && (
                          <span className="pl-2 text-muted k-regular-11">
                            ✔ Followers
                          </span>
                        )}
                    </div>

                    <p className="m-0 p-0 k-regular-11 text-secondary">
                      {isOnline ? "Online" : "Offline"}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="d-flex align-items-center float-right pointer-cursor"
            style={{ gap: "5px" }}
          >
            {this.state?.status &&
              this.props.selectedDiscussion?.subject?.pseudo !==
                "Unknown user" &&
              !this.state.isBlocked &&
              !this.state.isBlocker &&
              this.getButton()}
            {this.props?.selectedDiscussion &&
              this.onRenderPopOver(
                points,
                31,
                this.props?.selectedDiscussion?.subject?.pseudo ||
                  this.props?.selectedDiscussion?.user?.pseudo,
                this.props?.selectedDiscussion?.subject?.id ||
                  this.props?.selectedDiscussion?.user?.id,
                "signal",
                this.props.selectedDiscussion?.discussion?.id
              )}
            {!this.state.isBlocker &&
              ((this.props.selectedDiscussion &&
              this.props.selectedDiscussion?.discussion?.members.length <=
                2) || (this.state.isNewUser && this.state.newUserSearch.pseudo)) && (
                <Link
                  style={{ decoration: "none", color: "black" }}
                  to={{
                    pathname: `/details-user/${pseudo ? pseudo : this.state.newUserSearch.pseudo}`,
                    state: {
                      id: idUser,
                    },
                  }}
                >
                  <img src={InfoBtn} alt="info" />
                </Link>
              )}
          </div>
        </div>

        <div id="scrollableDiv" className="discussionList scrollable">
          {/*Put the scroll bar always on the bottom*/}
          <InfiniteScroll
            // dataLength={this.state.items.length}
            dataLength={this.props.messages.length}
            next={this.fetchMoreData2}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflowX: "hidden",
              overflowY: "hidden",
            }} //To put endMessage and loader to the top.
            inverse={true}
            hasMore={true}
            // loader={<h4>Loading...</h4>}
            loader={
              <h6
                style={{
                  textAlign: "center",
                  backgroundColor: "rgb(255, 224, 92)",
                }}
              >
                {this.state.loadFetch ? "Loading..." : "first message"}
              </h6>
            }
            scrollableTarget="scrollableDiv"
          >
            {/* {this.state.items.map((_, index) => (
											<div key={index} style={{width:600}}>
												div - #{index}
											</div>
										))} */}
            {[...this.props.messages].reverse().map((item, index) => {
              return (
                <>
                  <Message
                    index={index}
                    key={item.id || index}
                    refreshMessage={this.props.refreshMessage}
                    messageId={item.id}
                    userImg={item.img}
                    isSentByCurrentUser={item.isSentByCurrentUser}
                    time={timeDiff(item.time, true)}
                    content={item.content}
                    files={item.files}
                  />
                </>
              );
            })}
          </InfiniteScroll>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "65px",
            backgroundColor: "#0000005f",
          }}
          className="row mx-0 justify-content-start w-100"
        >
          {this.state.listFile.map((elt, ind) => (
            <div className="col-4 col-md-2">
              <div
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "-15px",
                  margin: "10px 10px",
                  width: "25px",
                  height: "25px",
                }}
                className="rounded-circle text-center pointer-cursor"
                onClick={() => this.handleRemoveClickPhoto(ind)}
              >
                <img
                  src={close}
                  alt=""
                  width={"16px"}
                  className="rounded-circle"
                  style={{ backgroundColor: "white" }}
                />
              </div>
              {elt.includes("video/mp4") ? (
                <video
                  src={elt}
                  alt=""
                  width={"100%"}
                  height={"100px"}
                  className="my-2"
                  autoPlay
                  muted
                  onClick={() => this.handleRemoveClickPhoto(ind)}
                  style={{ objectFit: "cover" }}
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  onLoadedData={() => this.setState({ isLoading: false })}
                />
              ) : (
                <img
                  src={elt.includes("image") ? elt : musicDefaultPreview}
                  alt=""
                  width={"100%"}
                  height={"100px"}
                  className="my-2"
                  style={{ objectFit: "contain" }}
                />
              )}
            </div>
          ))}
        </div>

        <div
          className="inputMessageText"
          style={{ bottom: "0", backgroundColor: "#fff" }}
        >
          {!this.state.isBlocked && !this.state.isBlocker ? (
            this.inputMessage(idUser)
          ) : (
            <p>You can't send messages to this user</p>
          )}
        </div>
      </div>
    );
  };

  // Remove photo selection
  handleRemoveClickPhoto = (index) => {
    let list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({ inputList: list });
    // remove the picture from list base 64
    let listFiletmp = [...this.state.listFile];
    listFiletmp.splice(index, 1);
    this.setState({ listFile: listFiletmp });
  };

  // Upload file in discussion input file
  handleUploadFile = (event) => {
    const maxSize = 400 * 1024 * 1024; // 40 MB in bytes
    let reader = new FileReader();
    let file = event;
    if (file.size > maxSize) {
      // Afficher un message d'erreur ou prendre des mesures appropriées
      toast.error("File size exceeds the maximum limit (400 MB).", {
        position: toast.POSITION.TOP_CENTER,
        // *style: { color: 'red', backgroundColor: 'transparent', marginTop: '150px' }
      });
      return;
    }
    if (this.state.isLoading) {
      toast.info("File upload is already in progress.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    this.setState({ errors: false, isLoading: true });
    // this.setState({ errors: false , isLoading: true, uploadProgress: 0});
    reader.onloadend = () => {
      const list = [...this.state.inputList];
      const listFileTmp64 = [...this.state.listFile];
      list.push(file);
      listFileTmp64.push(reader.result);
      // this.setState({ inputList: list, listFile: listFileTmp64 }, () => {
      // 	this.setState({ isLoading: false });
      // });
      this.setState({ inputList: list });
      this.setState({ listFile: listFileTmp64 });
      this.setState({ isLoading: false });
    };
    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        // Mettre à jour le state du chargement
        this.setState({ uploadProgress: progress });
      }
    };
    // reader.onload = () => {
    // 	this.setState({ isLoading: false });
    // };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  handleUploadScreenFile(medias, type) {
    let list = [...this.state.inputList];
    let listFileTmp64 = [...this.state.listFile];
    if (this.state.isLoading) {
      toast.info("File upload is already in progress.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (type === "IMAGE") {
      list = [
        ...list,
        ...medias.map((media, index) => {
          const mime = media.match(/data:(.*);base64/)[1];
          const blob = convert64IntoFile(media, mime);
          return new File([blob], `image${index}.png`, { type: mime });
        }),
      ];
      listFileTmp64 = [...listFileTmp64, ...medias];
      this.setState({ inputList: list });
      this.setState({ listFile: listFileTmp64 });
      this.setState({ isLoading: false });
    } else {
      const file = new File([medias], `video.mp4`, { type: "video/mp4" });
      list.push(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        listFileTmp64.push(reader.result);
        this.setState({ inputList: list });
        this.setState({ listFile: listFileTmp64 });
        this.setState({ isLoading: false });
      };
      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          // Mettre à jour le state du chargement
          this.setState({ uploadProgress: progress });
        }
      };
    }
  }

  // Step 2 Press onKeyDown
  inputMessage = (item) => {
    const { isLoading } = this.state;
    return (
      <>
        {!this.props.selectedDiscussion?.blocked_status ? (
          <div className="inputMessageBox w-100">
            <InputGroup
              className="my-2 inp-group"
              style={{
                borderRadius: "125rem",
                border: "solid 1px #303030",
                width: "100%",
              }}
            >
              {!this.state.isLoading && (
                <div className="d-flex align-items-center">
                  <ModalPaid
                    label="RequestPaid"
                    button="danger"
                    client_id={item}
                    cdm={(data) => this.setPI(data)}
                  />
                  <FileUploader
                    style={{ width: "20px !important" }}
                    id="picture"
                    name="picture"
                    ref={this.inputRef}
                    handleChange={(event) => this.handleUploadFile(event)}
                    classes={"fileUploader mb-1"}
                    fileOrFiles
                    types={["jpg", "png", "mp4", "avi", "mp3"]}
                    children={<ImageIcon className="inputMessageIcon mb-0" />}
                  />
                  <FaCamera
                    className="ml-1 mb-1"
                    size={20}
                    onClick={() => {
                      this.setState((state) => {
                        return { ...state, showCamera: !state.showCamera };
                      });
                    }}
                  />
                </div>
              )}
              <FormControl
                placeholder="Your answer"
                className="k-regular-15 bg-transparent pb-0"
                value={this.state.newMessage}
                onChange={this.handleChangeCommentInput}
                onKeyDown={this.onKeyDown}
                style={{
                  fontFamily: "KohinoorRegular",
                  border: "0",
                  borderRadius: "0rem 25rem 25rem 0",
                }}
              />

              {!this.state.isLoading &&
                (this.state.isNewMessage ? (
                  <button
                    type="submit"
                    className="btn k-regular-15"
                    onClick={this.submitNewMessage}
                    disabled={
                      !this.state.newMessage && !this.state.inputList.length
                    }
                  >
                    {" "}
                    {/* color={`${this.state.newReceivers.length > 1 ? "green" : "red"}`} */}
                    <BiSend size={25} />
                    {/* Send  */}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn k-regular-15"
                    disabled={
                      !this.state.newMessage && !this.state.inputList.length
                    }
                    onClick={(e) => {
                      this.submitMessage();
                    }}
                  >
                    <BiSend size={25} />
                    {/* Send  */}
                  </button>
                ))}
              {isLoading && (
                <div className="d-flex justify-content-center align-items-center mr-3">
                  <Spinner className="loadingSpinner" />
                </div>
              )}
            </InputGroup>
          </div>
        ) : (
          <p>You can't send messages to this user</p>
        )}
      </>
    );
  };

  // Step 3
  onKeyDown = (event) => {
    if (event.key === "Enter") {
      if (this.state.newMessage.length) {
        this.state.isNewMessage
          ? this.submitNewMessage()
          : this.submitMessage();
      } else {
        if (this.state.inputList.length > 0) {
          this.state.isNewMessage
            ? this.submitNewMessage()
            : this.submitMessage();
        }
      }
    }
  };

  // Step 4 Push message and Send to socket
  submitMessage = (data) => {
    this.setState({ isLoading: true });
    // If the message contains files (images/videos/audio...)

    const formData = new FormData();

    this.state.inputList.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    formData.append("sender", this.props.user.id);
    if (data) {
      formData.append("message", data);
    } else {
      formData.append(
        "message",
        this.state.newMessage ? this.state.newMessage : ""
      );
    }
    formData.append("discussion", this.props.selectedDiscussion.discussion.id);

    WS.axiosPostMessageWithFile(formData, `${api.messages}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            newMessage: "",
            images: [],
            inputList: [],
            listFile: [],
            isLoading: false,
          });

          // this.setState({ newMessage: ''});
          // this.sort(this.props.discussions);
          const allMessages = [...this.props.messages];

          const lastMessage = {
            content: response.data.data.lastMessage.message,
            id: response.data.data.lastMessage.id,
            time: Date.now(),
            files: response.data.data.lastMessage.files,
            isSentByCurrentUser:
              response.data.data.lastMessage.sender.id === this.props.user.id,
            img: defaultProfile,
          };
          allMessages.push(lastMessage);
          this.props.showMessages(allMessages);

          getAllDicussions().then((data) => {
            this.scrollToLastMsg();
            this.props.showAllDiscussions(data.data.content);
          });
        }
      })
      // .catch((err) => console.log("Error response message", err));
  };

  submitNewMessage = () => {
    this.setState({ isLoading: true });
    const listReicevers =
      this.state.newReceivers.length > 1
        ? this.state.newReceivers.map((rep) => rep.value)
        : [this.state.newReceivers[0].value];
    const newMsg = this.state.newMessage;

    if (this.state.inputList.length > 0) {
      createNewDiscussion({
        message: `${this.props.user.pseudo} creates the discussion`,
        receivers: listReicevers,
        sender: this.props.user.id,
      }).then((rep) => {
        this.getAllMessages(rep.data).then(() => {
          this.RefreshListDiscussion(rep.data, newMsg, true);
          const formData = new FormData();

          this.state.inputList.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });

          formData.append("sender", this.props.user.id);
          formData.append("message", newMsg);

          if (rep.data?.discussion?.id) {
            formData.append("discussion", rep.data?.discussion?.id);

            WS.axiosPostMessageWithFile(formData, `${api.messages}`)
              .then((response) => {
                this.props.setSelectedDiscussion(rep.data);
                if (response.status === 200) {
                  this.setState({
                    newMessage: "",
                    images: [],
                    inputList: [],
                    listFile: [],
                    isLoading: false,
                  });

                  const allMessages = [...this.props.messages];

                  const lastMessage = {
                    content: response.data.data.lastMessage.message,
                    id: response.data.data.lastMessage.id,
                    time: Date.now(),
                    files: response.data.data.lastMessage.files,
                    isSentByCurrentUser:
                      response.data.data.lastMessage.sender.id ===
                      this.props.user.id,
                    img: defaultProfile,
                  };
                  allMessages.push(lastMessage);
                  this.props.showMessages(allMessages);
                  this.props.setSelectedDiscussion(rep.data);
                  this.scrollToLastMsg();

                  getAllDicussions().then((data) => {
                    this.props.showAllDiscussions(data.data.content);
                    this.setState({
                      newMessage: "",
                      images: [],
                      newReceivers: [],
                      openNewMessage: false,
                      newUserIdLocation: "",
                      newUserSearch: {},
                      isNewMessage: false,
                    });
                  });
                }
              })
              // .catch((err) => console.log("Error response message", err));
          }
        });
      });
    } else if (this.state.newMessage.trim().length > 0) {
      createNewDiscussion({
        message: newMsg,
        receivers: listReicevers,
        sender: this.props.user.id,
      }).then((rep) => {
        const updatediscussion = [rep.data, ...this.props.discussions];
        this.props.showAllDiscussions(updatediscussion);
        this.getAllMessages(rep.data).then(() => {
          this.RefreshListDiscussion(rep.data, newMsg);
          this.setState({
            newMessage: "",
            images: [],
            newReceivers: [],
            openNewMessage: false,
            newUserIdLocation: "",
            newUserSearch: {},
            isNewMessage: false,
            isLoading: false,
          });
        });
      });
    }
  };

  scrollToLastMsg() {
    let msgLB = document.querySelector("#scrollableDiv");
    if (msgLB) {
      msgLB.scrollTop = msgLB.scrollHeight;
    }
  }

  // Step 5 New discussion
  sendMessageSocket = (isData) => {
    if (!this.props.selectedDiscussion && this.state.openNewMessage) {
      if (this.state.newReceivers.length < 1) {
        // toast.error('Veuillez choisir un utilisateur pour pouvoir discuter :)');
        return;
      }
      const receivers = this.state.newReceivers.map((u) => u.value);

      // rabbitmq.send(`/topic/new.message.${receivers[0]}`, {
      // 	message: isData? isData : this.state.newMessage,
      // 	receivers,
      // 	sender: this.props.user.id,
      // })
      SocketClient.send("/app/new.message", {
        message: isData ? isData : this.state.newMessage,
        receivers,
        files: this.state.inputList,
        sender: this.props.user.id,
      });

      this.componentDidMount();

      // // Redirect after new message sent to socket
      this.openDialogMessage();
      this.setState({ openNewMessage: false });
      // this.getAllMessages(
      // 	this.props.discussions[this.props.discussions?.length - 1]
      // );
      this.props.setSelectedDiscussion(
        this.props.discussions[this.props.discussions?.length - 1]
      );
    }
  };

  updateDiscussion = () => {
    this.setState({ updateDiscussion: !this.state.updateDiscussion });
  };

  // Step 6 Existante discussion
  answerMessageSocket = (newMessage) => {
    if (this.props.selectedDiscussion) {
      SocketClient.send(`/app/sendto.message`, {
        message: newMessage,
        discussion: this.props.selectedDiscussion.discussion?.id,
        sender: this.props.user.id,
      });
    }
  };

  // Step 7 Afficher une discussion déjà existante
  openNewMessage = () => {
    this.setState({ openNewMessage: true });
  };

  handleSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value });
    if (e.target.value !== "") {
      searchUserByPseudo(e.target.value).then((response) => {
        this.setState({ resultSearchUser: response.data.data.content });
      });
    }
  };
  // MOBILE and WEB search bar
  onRenderSearchForm = () => {
    return (
      <div className="inputSearch w-100">
        <InputGroup
          className="mb-3"
          id="select-mobile"
          style={{
            borderRadius: "125rem",
            border: "solid 1px #C4C4C6",
            position: "relative",
          }}
        >
          <button className="btn">
            <img src={ic_search} alt="" />
          </button>
          {this.state.openNewMessage ? (
            <>
              {/* Select New Discussion */}
              <div
                className="d-flex flex-row new-user-wapper"
                style={{ width: "85%" }}
              >
                <Select
                  // placeholder="Nouvelle discussion"
                  placeholder="News discussion"
                  multi
                  dropdownHandleRenderer={() => null}
                  style={{
                    fontFamily: "KohinoorRegular",
                    border: "0",
                    borderRadius: "0rem 25rem 25rem 0",
                    width: "100%",
                  }}
                  itemRenderer={this.customItemRenderer}
                  onChange={(e) => this.setState({ newReceivers: e })}
                  values={this.state.newReceivers}
                  options={this.options()}
                />
              </div>
            </>
          ) : (
            // Select Existing Discussion
            <div
              className="d-flex flex-row new-user-wapper"
              style={{ width: "85%" }}
            >
              <Select
                placeholder="Search"
                multi
                dropdownHandleRenderer={() => null}
                style={{
                  fontFamily: "KohinoorRegular",
                  border: "0",
                  borderRadius: "0rem 25rem 25rem 0",
                  width: "100%",
                }}
                itemRenderer={this.customItemRenderer}
                // Step 1 select newReceivers
                onChange={(e) => {
                  this.openMessageMobile(e[0]?.value, e[0]?.pseudo);
                }}
                values={this.state.newReceivers}
                options={this.optionsSelectMobile()}
              />
            </div>
          )}
        </InputGroup>
        {this.state.openNewMessage && this.state.newReceivers.length >= 1 && (
          <button
            type="button"
            className="btn btn-sm btn-rounded k-regular-13 w-100"
            style={{
              fontFamily: "KohinoorRegular",
              border: "solid 1px #F7CC2A",
            }}
            onClick={() => {
              if (this.state.newReceivers.length === 1) {
                // New Simple Discussion
                this.openMessageMobile(
                  this.state.newReceivers[0]?.value,
                  this.state.newReceivers[0]?.pseudo,
                  this.state.newReceivers[0]?.value
                );
              } else if (this.state.newReceivers.length > 1) {
                // New Group Discussion
                this.openGroupMessageMobile(
                  this.state.newReceivers[0]?.value,
                  this.state.newReceivers
                );
              }
            }}
          >
            Create new discussion
          </button>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    messages: state.chatReducers.messages,
    newMessageCount: state.chatReducers.newMessages,
    discussions: state.chatReducers.discussions,
    discussion: state.chatReducers.selectedDiscussion,
    user: state.compteReducers.userConnected,
    selectedDiscussion: state.chatReducers.selectedDiscussion,
    updateDiscussion: state.chatReducers.updateDiscussion,
    isConnected: state.compteReducers.isConnected,
    refreshDiscussion: state.chatReducers.refreshDiscussion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showAllDiscussions: (data) => dispatch(showAllDiscussion(data)),
    onNewMessage: (data) => dispatch(newMessage(data)),
    showMessages: (data) => dispatch(showMessages(data)),
    setSelectedDiscussion: (data) => dispatch(setSelectedDiscussion(data)),
    setUpdateDiscussion: (data) => dispatch(setUpdateDiscussion(data)),
    setRefreshDiscussion: (data) => dispatch(refreshDiscussion(data)),
    setCouterMessage: (data) => dispatch(setCouterMessage(data)),
    setTotalCounter: (data) => dispatch(setTotalCounter(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesDetails);
