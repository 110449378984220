import Stomp from "stompjs";
import SockJS from "sockjs-client";
import { stompConfig } from "../res/constants";
import { store } from "../App";

class _SocketClient {
  socket = {};
  stompClient = {};
  compte = 0;
  constructor() {
    this.connect.bind(this);
  }

  // VERIFIE TOKEN, NEN'LE CHARGER FARANY FONA NO APESAINY FA TSY LE VAO2
  connect = (param) => {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem("token")) {
        reject("User not connected ...");
        return;
      }

      // if(store.getState().compteReducers.isConnected === false){
      //   reject('User not connected ...');
      //   return
      // }

      if (this.compte >= 1) {
        window.location.reload();
        return;
      }

      setTimeout(() => {
        const header = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        this.socket = new SockJS(stompConfig.url);
        this.stompClient = Stomp.over(this.socket);
        // this.stompClient.debug = stompConfig.debug;
        this.stompClient.debug = () => {};

        this.stompClient.connect(
          header,
          (frame) => {
            // stompConfig.headers || header
            this.compte = 0;
            // console.log('Frame', frame);
            resolve(this.stompClient);
          },
          (error) => {
            console.log("Error promise stompClient : TRY TO RECONNECT ", error);
            this.compte++;

            setTimeout(() => {
              this.connect(); // localStorage.getItem('token')
            }, 1000);
          }
        );
      }, 1000);
    });
  };

  send(topic, body) {
    this.stompClient.send(topic, {}, JSON.stringify(body));
  }
}

const SocketClient = new _SocketClient();

export default SocketClient;
