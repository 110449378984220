import axios from 'axios';
import { api } from '../../../res/constants';

const axiosGet = (endpoint) =>
	axios({
		method: 'GET',
		url: api.baseUrl + endpoint,
		headers: { 'Content-Type': 'multipart/form-data' },
	});

const axiosGetWithParams = ({ params, endpoint }) =>
	axios.get(api.baseUrl + endpoint, { params });

const axiosGetWithParamsSecure = (params, endpoint) =>
	axios({
		method: 'GET',
		url: api.baseUrl + endpoint,
		data: params,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
	});
const axiosPostWithParamsSecure = ({ params, endpoint }) =>
	axios({
		method: 'POST',
		url: endpoint,
		data: params,
		headers: {
			'Content-Type': 'application/json',
		},
	});

const axiosSecureGet = (endpoint) =>
	axios({
		method: 'GET',
		url: api.baseUrl + endpoint,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
	});

const axiosPostURL = (params, endpoint) =>
	axios({
		method: 'POST',
		url: `${api.baseUrl}${endpoint}`,
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		data: params,
	});
const axiosPostWithToken = (endpoint) =>
	axios({
		method: 'POST',
		url: `${api.baseUrl}${endpoint}`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
	});

const axiosPutWithToken = (endpoint) =>
	axios({
		method: 'PUT',
		url: `${api.baseUrl}${endpoint}`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
	});

const axiosGetWithToken = ({ endpoint, data }) =>
	axios({
		method: 'GET',
		url: `${api.baseUrl}${endpoint}`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: data,
	});

const axiosDeleteWithToken = (endpoint) =>
	axios({
		method: 'DELETE',
		url: `${api.baseUrl}${endpoint}`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
	});

const axiosPostURLJson = (params, endpoint) =>
	axios({
		method: 'POST',
		url: `${api.baseUrl}${endpoint}?code=${params.code}&redirect_url=${params.redirect_url}`,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		},
		// data: params,
	});

const axiosPostFormData = (formData, endpoint) =>
	axios({
		method: 'POST',
		url: api.baseUrl + endpoint,
		headers: { 'Content-Type': 'multipart/form-data' },
		data: formData,
	});

const axiosPostFormDataWithToken = (formData, endpoint) => 
	axios({
		method: 'POST',
		url: api.baseUrl + endpoint,
		headers: { 
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('token')}`
		},
		data: formData,
	})

const axiosPutFormData = (formData, endpoint) =>
	axios({
		method: 'PUT',
		url: api.baseUrl + endpoint,
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: formData,
	});
const axiosPutFormUrlEncoded = (formData, endpoint) =>
	axios({
		method: 'PUT',
		url: api.baseUrl + endpoint,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: formData,
	});
const axiosSecurePost = (formData, endpoint) =>
	axios({
		method: 'POST',
		url: api.baseUrl + endpoint,
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: formData,
	});

const axiosPostPicture = (formData, endpoint) =>
	axios({
		method: 'POST',
		url: api.baseUrl + endpoint,
		headers: { 'Content-Type': 'image/png' },
		data: formData,
	});

const axiosPostMessageWithFile = (formData, endpoint) => 
	axios({
		method: 'POST',
		url: api.baseUrl + endpoint,
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		data: formData
	})

const axiosSecurePostWithToken = ( endpoint, data ) => 
	{
		const fd = new FormData();
		fd.append('amount', data.amount);
		fd.append('email', data.email);
		fd.append('methodPayment', data.methodPayment);
		fd.append('pseudo', data.pseudo);
		return axios({
			method: 'POST',
			url: `${api.baseUrl}${endpoint}`,
			data: fd,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
	}


const axiosSecureGetOrderId = (params) => {
	return axios({
		method: 'POST',
		url: `${api.baseUrl}/orders?adresse.adresse=${params.adresse}&adresse.codePostale=${params.zip_code}&adresse.nom=${params.lastname}&adresse.prenom=${params.firstName}&adresse.ville=${params.city}&adresse.pays=${params.country}&adresse.paymentType=${params.methodPayment}`,
		headers: {
			Authorization: `Bearer ${localStorage.getItem('token')}`
		}
	})
}


const axiosSecurePutWithToken = (endpoint, data) =>
    axios({
        method: 'PUT',
        url: `${api.baseUrl}${endpoint}`,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })


const WS = {
	axiosGet,
	axiosPostURL,
	axiosPostFormData,
	axiosPutFormData,
	axiosPutFormUrlEncoded,
	axiosPostPicture,
	axiosSecureGet,
	axiosPostURLJson,
	axiosGetWithParams,
	axiosSecurePost,
	axiosPostWithToken,
	axiosPutWithToken,
	axiosGetWithToken,
	axiosDeleteWithToken,
	axiosGetWithParamsSecure,
	axiosPostWithParamsSecure,
	axiosPostMessageWithFile,
	axiosSecurePostWithToken,
	axiosSecureGetOrderId,
	axiosSecurePutWithToken,
	axiosPostFormDataWithToken
};
export default WS;
