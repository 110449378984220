// COMPTE REDUCERS
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';

export const LOGOUT = 'LOGOUT';
export const DETAILS_USER_CURRENT = 'DETAILS_USER_CURRENT';

// CHAT REDUCERS
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const GET_ALL_DISCUSSION = 'SHOW_ALL_DISCUSSION';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_SELECTED_DISCUSSION = 'SET_SELECTED_DISCUSSION';
export const SET_UPDATE_DISCUSSION = 'SET_UPDATE_DISCUSSION';
export const REFRESH_DISCUSSION = 'REFRESH_DISCUSSION';
export const SET_SOCKET_CONNECTED = 'SOCKET_CONNECTED';

// MODAL REDUCERS
export const SHOW_MODAL_COMMENTAIRE = 'SHOW_MODAL_COMMENTAIRE';
export const GET_PARENT_ID = 'GET_PARENT_ID';
export const GET_IS_COMMENT = 'GET_IS_COMMENT';
export const GET_COMMENT_COUNT = 'GET_COMMENT_COUNT';

// CONTENT REDUCERS
export const CONTENT_RECEIVED = 'CONTENT_RECEIVED';
export const CONTENT_SIGNAL_ID = 'CONTENT_SIGNAL_ID';
export const COMMENT_RECEIVED = 'COMMENT_RECEIVED';

// PANIERS REDUCERS
export const SET_PANIERS_COUNTER_ADD = 'SET_PANIERS_COUNTER_ADD';
export const SET_PANIERS_COUNTER_REMOVE = 'SET_PANIERS_COUNTER_REMOVE';
export const SET_PANIERS_COUNTER_RESET = 'SET_PANIERS_COUNTER_RESET';
export const SET_PANIERS_COUNTER = 'SET_PANIERS_COUNTER';

// ORDERS REDUCERS
export const SET_ORDERS_COUNTER_ADD = 'SET_ORDERS_COUNTER_ADD';
export const SET_ORDERS_COUNTER_REMOVE = 'SET_ORDERS_COUNTER_REMOVE';
export const UPDATE_NEW_ITEMS_COUNT = 'UPDATE_NEW_ITEMS_COUNT';
export const SET_ORDERS_COUNT = 'SET_ORDERS_COUNT';

// NOTIFICATION REDUCERS
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT';
export const SET_MESSAGE_COUNT = 'SET_MESSAGE_COUNT';
export const INCREMENT_NOTIFICATION_COUNT = 'INCREMENT_NOTIFICATION_COUNT';
export const RESET_NOTIFICATION_COUNT = 'RESET_NOTIFICATION_COUNT';

// COUNTER REDUCER
export const SET_TOTAL_COUNTER = 'SET_TOTAL_COUNTER';
export const RESET_TOTAL_COUNTER = 'REMOVE_TOTAL_COUNTER';
export const INCREMENT_MESSAGE_COUNT = 'INCREMENT_MESSAGE_COUNT';


//NOTIFICATION REDUCERS
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';