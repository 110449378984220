import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  cancelNewRequestFriend,
  friendRequestRefuse,
  friendRequestValidate,
  getAllNewRequestFriend,
  searchUserByPseudo,
  sendNewRequestFriend,
} from "../../services/stores/actions/compte";
import { ModalDetailsUser } from "../Modal/ModalDetailsUser";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { currentUserSelector } from "../../services/stores/selectors/selectors";
import user_add from "../../assets/profil/user_add.svg";
import logoBeee from "../../assets/logoBee.png";
import WS from "../../services/stores/actions/api";
import { api } from "../../res/constants";

/**
 *
 * @param pseudo id of user to be follow
 * @param setStateOfParent method used for the update parent component
 * @returns
 */
export default function Followup({
  id,
  isFriend,
  setStateOfParent,
  isConnected,
  pseudo,
  listRequestFriends,
  friend_up,
  label,
  setShowLoading,
  followState,
  isMobile
}) {
  let history = useHistory();
  // const [showLoading, setShowLoading] = useState(false);
  // const [abonner, setAbonner] = useState(false);
  const [isSender, setIsSender] = useState(false);
  const [show, setShow] = useState(false);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [friend, setFriend] = useState(null);
  const [_id, setId] = useState("");
  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    searchUserByPseudo(pseudo).then((response) => {
      setContent(response.data.data.content[0]);
      if (friend_up) {
        setFriend(friend_up);
        setIsLoading(false);
      } else {
        checkIsFriend(response.data.data.content[0]?.id);
      }
      setNeedUpdate(false);
    });
  }, [needUpdate]);

  const checkIsFriend = (id) => {
    setId(id);
    if (user.isConnected) {
      WS.axiosSecureGet(`${api.checkFriend}/${id}`)
        .then((response) => {
          if (response.data.data) {
            setFriend(response.data.data[0]);
          } else {
            setContent([{ ...content, is_friends: false, id: id }]);
          }
          setIsLoading(false);
        })
        .catch((err) => console.log("ERROR_CHECK_FRIEND", err));
    }
  };

  // selector for current user
  const user = useSelector(currentUserSelector);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <>
        {user.userConnected?.id !== content?.id &&
          (followState ? (
            // <h1>Action exist</h1>
            <React.Suspense>
              {followState[0]?.status === "CREATED" ||
              followState[0]?.status === "REFUSED" ? (
                <div className="pointer-cursor" onClick={() => handleShow}>
                  <ModalDetailsUser
                    title={label ? "Unfollow" : "Pending"}
                    isSender={true}
                    custom={label ? false : true}
                    button={"Withdraw my request"}
                    onClickSender={() => {
                      setShowLoading(true);
                      cancelNewRequestFriend(friend?.id).then((cancel) => {
                        // setAbonner(false);
                        setIsSender(false);
                        setStateOfParent();
                        setShowLoading(false);
                        setNeedUpdate(!needUpdate);
                      });
                    }}
                  />
                </div>
              ) : (
                <ModalDetailsUser
                  title={`Friend`}
                  button={"Remove friend"}
                  custom={label ? false : true}
                  isSender={true}
                  onClickSender={() => {
                    setShowLoading(true);
                    cancelNewRequestFriend(followState[0]?.user_full.id).then(
                      (cancel) => {
                        // setAbonner(false);
                        setIsSender(false);
                        setStateOfParent();
                        setShowLoading(false);
                        setNeedUpdate(!needUpdate);
                      }
                    );
                  }}
                />
              )}

              {(followState[0]?.status === "CREATED" ||
                followState[0]?.status === "REFUSED") &&
              followState[0]?.sender === user.userConnected.id &&
              followState[0]?.user === content?.id ? (
                // IF SENDER
                <div className="pointer-cursor" onClick={() => handleShow}>
                  <h1>Action exist 1</h1>
                  <ModalDetailsUser
                    title={label ? "Unfollow" : "Pending"}
                    isSender={true}
                    custom={label ? false : true}
                    button={"Withdraw my request"}
                    onClickSender={() => {
                      setShowLoading(true);
                      cancelNewRequestFriend(friend?.id).then((cancel) => {
                        // setAbonner(false);
                        setIsSender(false);
                        setStateOfParent();
                        setShowLoading(false);
                        setNeedUpdate(!needUpdate);
                      });
                    }}
                  />
                </div>
              ) : followState[0]?.status === "VALIDATED" &&
                followState[0]?.sender === user.userConnected.id &&
                followState[0]?.user === content?.id ? (
                <div className="pointer-cursor" onClick={handleShow}>
                  <h1>Action exist 2</h1>
                  <ModalDetailsUser
                    title={`Friend`}
                    button={"Remove friend"}
                    custom={label ? false : true}
                    isSender={true}
                    onClickSender={() => {
                      setShowLoading(true);
                      cancelNewRequestFriend(followState[0]?.user_full.id).then(
                        (cancel) => {
                          // setAbonner(false);
                          setIsSender(false);
                          setStateOfParent();
                          setShowLoading(false);
                          setNeedUpdate(!needUpdate);
                        }
                      );
                    }}
                  />
                </div>
              ) : null}
            </React.Suspense>
          ) : (
            <div className="pointer-cursor">
              <button
                className="btn btn-yellow btn-rounded k-semibold-12"
                onClick={() => {
                  setShowLoading(true);
                  sendNewRequestFriend(_id).then(() => {
                    setStateOfParent();
                    setShowLoading(false);
                    setIsSender(false);
                    setNeedUpdate(!needUpdate);
                  });
                }}
              >
                {/* {!isMobile && <img src={user_add} alt="icon-add" />} */}
                <img src={user_add} alt="icon-add" className="mr-1" width="13px" height="13px" />
                 {label ? label : "Add"}
              </button>
            </div>
          ))}

        <React.Suspense
          fallback={
            <div className="text-center pulse">
              <img src={logoBeee} alt="Chargement..." className="bee" />
            </div>
          }
        >
          {user.userConnected?.id !== content?.id &&
          // IF RECEIVER
          friend?.status === "VALIDATED" &&
          friend?.sender === content?.id &&
          friend?.user === user.userConnected.id ? (
            <div className="pointer-cursor" onClick={handleShow}>
              <ModalDetailsUser
                title={`Friend`}
                button={"Remove friend"}
                custom={label ? false : true}
                status={friend?.status}
                isSender={isSender}
                onClickRefuse={() => {
                  setShowLoading(true);
                  friendRequestRefuse(friend?.id).then((refuse) => {
                    // setAbonner(false)
                    setIsSender(false);
                    setStateOfParent();
                    setShowLoading(false);
                    setNeedUpdate(!needUpdate);
                  });
                }}
                onClickValidate={() => {
                  setShowLoading(true);
                  friendRequestValidate(friend?.id).then((validate) => {
                    // setAbonner(false)
                    setIsSender(false);
                    setStateOfParent();
                    setShowLoading(false);
                    setNeedUpdate(!needUpdate);
                  });
                }}
              />
            </div>
          ) : friend?.status === "CREATED" &&
            friend?.user === user.userConnected.id &&
            friend?.sender === content?.id ? (
            <div className="pointer-cursor" onClick={handleShow}>
              <ModalDetailsUser
                title={`Respond`}
                button={"Decline request"}
                custom={label ? false : true}
                status={friend?.status}
                isSender={isSender}
                onClickRefuse={() => {
                  setShowLoading(true);
                  friendRequestRefuse(friend?.id).then((refuse) => {
                    // setAbonner(false)
                    setIsSender(false);
                    setStateOfParent();
                    setShowLoading(false);
                    setNeedUpdate(!needUpdate);
                  });
                }}
                onClickValidate={() => {
                  setShowLoading(true);
                  friendRequestValidate(friend?.id).then((validate) => {
                    // setAbonner(false)
                    setIsSender(false);
                    setStateOfParent();
                    setShowLoading(false);
                    setNeedUpdate(!needUpdate);
                  });
                }}
              />
            </div>
          ) : friend?.status === "REFUSED" &&
            friend?.sender === content?.id &&
            friend?.user === user.userConnected.id ? (
            <div className="pointer-cursor" onClick={handleShow}>
              <ModalDetailsUser
                title={`Request refused`}
                button={"Reject friend"}
                status={friend?.status}
                custom={label ? false : true}
                isSender={isSender}
                onClickRefuse={() => {
                  setShowLoading(true);
                  friendRequestRefuse(friend?.id).then((refuse) => {
                    // setAbonner(false)
                    setIsSender(false);
                    setStateOfParent();
                    setShowLoading(false);
                    setNeedUpdate(!needUpdate);
                  });
                }}
                onClickValidate={() => {
                  setShowLoading(true);
                  friendRequestValidate(friend?.id).then((validate) => {
                    // setAbonner(false)
                    setIsSender(false);
                    setStateOfParent();
                    setShowLoading(false);
                    setNeedUpdate(!needUpdate);
                  });
                }}
              />
            </div>
          ) : null}
        </React.Suspense>
      </>
    </>
  );
}

Followup.defaultProps = {
  setStateOfParent: () => {},
};
