import React from "react";

import { Form } from "react-bootstrap";

const CGU = (props) => {
  return (
    <Form className="container d-flex flex-column" style={{ height: "80vh" }}>
      <Form.Group className="text-center">
        <Form.Label className="font-weight-bold k-semibold-20">CGU</Form.Label>
      </Form.Group>
      <Form.Group className="text-center"> 
        <Form.Label htmlFor="disabledTextInput" className="k-regular-13">
			<p className="my-0 text-center k-semibold-20">
				Welcome to our MyBeeDoo Services operated by SAS HAKA.
			</p>
			<p>
				By using our services, you agree to be bound by these terms and
				conditions.
			</p>
			<p>
				Please read these Terms and Conditions carefully. They establish the
				terms and conditions under which you may use our Services. If you
				agree to these Terms and Conditions, you may use our Site and
				application as a User and will be able to act as a Buyer and Seller
				of Items on the MyBeeDoo services as well as a creator of content.
				If you do not agree to these Terms and Conditions, you are not
				authorized to use our services.
			</p>
			<p>
				In case of a dispute, only the Terms and Conditions of Use of the
				date of the conflict will be valid. We reserve the right to change
				these terms and conditions without notice, Users will receive each
				update by newsletter and by banner for new users. If the User
				refuses to accept the terms and conditions of use, navigation on our
				services will be limited. We reserve the right to interrupt all or
				part of our services at any time without prior notice. Navigation on
				our servers is accessible 24 hours a day, 7 days a week, except in
				case of maintenance. MyBeeDoo cannot be held responsible for the
				unavailability of its services in case of voluntary or involuntary
				interruption, security or accessibility.
			</p>
			<p>1° Definitions</p>
				- Users: means any person using our web services and application.
				<br />
				- Visitor: person browsing our services without having an account.
				<br />
				- Creator: the one who publishes in any form of format on our
				services.
				<br />
				- Buyer: means a User who makes a purchase on our services.
				<br />
				- Seller: designates a User who lists and sells Articles on our site
				and application via our services
				<br />
				- Account: means a personal account containing the User's contact
				information.
				<br />
				- We, our: refers to the managers of MYBEEDOO.
				<br />
				- Content: all data and publications provided by the User on our
				services
				<br />
				- Site: refers to our entire site located at the URL <a href="https://site.mybeedoo.com/" target="_blank">www.mybeedoo.com</a>
				<br />
				- Marketplace: means our sales platform
				<br />
				- Terms of Use and Sales: means the following set of terms that
				apply to all persons using our services.
				<br />
				- Media: set of formats linked to a publication or content
				<br />
				- Private Content: all the media exchanged via the internal
				messaging system
				<br />
				- Our services: refers to all of our site: <a href="https://site.mybeedoo.com/" target="_blank">www.mybeedoo.com</a> and our application MyBeeDoo.
				<br />
				<br />
			<ol>2° Presentation of the Company</ol>
			<p>
				Our Services <a href="https://site.mybeedoo.com/" target="_blank">www.mybeedoo.com</a> and the MyBeedoo application are published by SAS HAKA, a simplified joint stock company whose head office is located at: 82 allée du Petit Pont, 83240 Cavalaire sur Mer, France and is registered in the Fréjus Trade and Companies Register RCS B 894 512 318 You can contact us by writing to the head office or by e-mail: support.client@mybeedoo.com. Any person having access to the internet is allowed to use our services free of charge. Our website and application are a social selling network allowing Users to share content, sell and buy items featured on the publications and exchange private content.
			</p>
			<p>
				<strong>
				MyBeedoo is hereinafter referred to in the T&Cs and usage as the
				company SAS HAKA.
				</strong>
			</p>
			<p>
				<strong>
				MyBeedoo refers to SAS HAKA in the following terms and conditions
				of use.
				</strong>
			</p>
			<p>
				MyBeeDoo offers Hosting Services as an intermediary between Buyers and Creators. MyBeeDoo also offers, for costs included in our commission, a Buyer Protection service applicable to all Transactions made using the Buy button, as well as Additional Services. MyBeeDoo is fully responsible for the Services it provides, within the limits of its commitments and under the conditions provided for in these General Conditions. MyBeeDoo is not party to any Transaction between Users. MyBeeDoo does not buy or sell the Items listed on our Services and application.
			</p>
			<ol>3° Creation and Deletion of the Account</ol>
			<p>
				If you are a Site Visitor, you can view public content, search and view certain Articles in the Catalog, read the Forum and other Content provided by the Creators, as well as News and other information published on our services. However, you will not be considered as a User and you will not be able to use all of our Services. These General Conditions may however have an informative
				value for you.
			</p>
			<p>
				You will be asked to create an account to view content not public, like, comment or share content, sell or buy an item, follow an account, contact a member, report activity or contact customer service.
			</p>
			<p>
				The data collected during the creation of the account such as your contact details, your password, remains strictly confidential.
			</p>
			<p>
				You can modify or delete these data at any time by clicking on the icon "My Account".
			</p>
			<p>
				To register on MyBeeDoo, the User must create an account with his email address. All the fields requested are mandatory in order to finalize the opening of the account:
			</p>
			• Gender <br />
			• Identify <br />
			• Date of birth <br />
			• Mail address <br />
			• Password <br />
			<br />
			<p>
				When registering on our Services, you agree to provide truthful, accurate, up-to-date and complete information. It is important that you keep and quickly update information relating to your Account in order to maintain this information up to date and complete at all times.
			</p>
			<p>
				To modify your personal data, simply write to us via internal messaging or by email: <a href="mailto:support.client@mybeedoo.com">support.client@mybeedoo.com</a>. You will be asked for proof of identification.
			</p>
			<p>
				A period of several weeks will be necessary for the treatment of the modifications for technical reasons. All data collected on our services must be provided by the User himself (person of full age and legally capable) in order to authenticate the veracity of the data.
			</p>
			<p>
				In case of fraudulent use, loss, or any other misuse of your usernames and passwords, we strongly advise you to contact MyBeeDoo and notify them of the usurpation of identity.{" "}
			</p>
			<p>
				You can delete your account by clicking on the “My Account” icon and then clicking on the “Delete My Account” button. Before proceeding with the final deletion of your account, a confirmation will be requested. Any deletion is permanent and results in the deletion of your personal data permanently.
			</p>
			<p>
				In order to protect your User Account and ensure the safety of other Users, MyBeeDoo may, if necessary, verify the information contained in your User Account or ask you to answer certain questions, provide certain information or participate in verification (the Security Procedures). The purpose of these Security Procedures is to verify that it is you who is accessing your User Account and/or carrying out transactions from it. These procedures will be reasonable and proportionate to the security objectives pursued. As part of the Security Procedures, MyBeeDoo may verify the telephone number and email address, as well as the details of the credit card, debit card or any other means of payment of the User and his Bank account.
			</p>
			<p>
				Unauthorized Users: Our Services are not intended to be used by persons under the age of 18. If you are a parent or guardian of a dependent under the age of 18, you can create an Account and accept these Terms and conditions on behalf of such minor, but you will assume full responsibility for the use of the Site by the minor and acknowledge that our services are intended for persons aged 18 and
				over.
			</p>
			<p>4° Content and intellectual property</p>
			<p>4°-1 Content</p>
			<p>
				Anyone can view or publish content, sell and buy items on our platform as soon as the account creation is validated. Specific information and documents will be requested to finalize the creation of a content creator account:
			</p>
			• Name <br />
			• First name <br />
			• ID <br />
			• phone number <br />
			• E-mail <br />
			• address <br />
			• Country <br />
			• Name of your other social networks <br />
			• selfie with ID <br />
			• Iban <br />

			<p>
				Once a User's registration on MyBeeDoo is finalized, they will be able to publish content in their feed, sell items, follow other creators and interact with other Users.
			</p>
			<p>4°-2 Intellectual property</p>
			<p>
				The User acknowledges that the Content are broadcast or published on the Platform under the responsibility of the Creator.
			</p>
			<p>
				In accordance with the General Conditions of Use (CGU), the Media published by the Creator are the exclusive property of the latter, which the User expressly accepts and acknowledges.
			</p>
			<p>
				Any representation or reproduction, total or partial, of the Media by any means whatsoever, without the prior authorization of the Creator, is prohibited and will constitute an infringement punishable by the provisions of the Intellectual Property Code, in accordance with the General Conditions of Use.
			</p>
			<p>
				Regarding Private Content, the Creator grants the User having placed the order a personal license for viewing, reproduction and representation in a private and/or family circle only. This license is granted for the whole world and for the entire duration of the intellectual property rights.
			</p>
			<p>Accordingly, the User agrees to:</p>
			<p>
				Use the Content published by the Creator only for his personal needs for the purpose of consulting the Media on the Platform, excluding any use of the Media for commercial or professional purposes;
			</p>
			<p>
				Not to publish, distribute or communicate all or part of the Media published by a Creator to third parties, by any means whatsoever, on any medium whatsoever, in particular on social networks, whether free of charge or for consideration;
			</p>
			<p>
				Not to modify, adapt or damage the integrity of all or part of the Media published by a Creator in any way
			</p>
			<p>
				Any downloading, sharing, provision, reproduction, or any other form of reproduction, use or distribution, excluding consultation on the Platform, infringes the rights of the Creator, of MyBeeDoo and engages the responsibility of the User.
			</p>
			<p>
				In case of serious suspicion of breach of these obligations, MyBeeDoo may delete the User's Account to its exclusive wrongs, without any reimbursement for the sums paid by the User being payable by the latter
			</p>
			<p>
				In the case of a proven failure to comply with these commitments, which are part of the essential commitments of the Parties, the User undertakes to pay a sum equal to $500 (including tax) or 10 times the amount paid by the User for the purchase or the Media concerned by the infringement, whichever amount is greater. The amount of this penalty will then be returned to the Creator
			</p>
			<p>
				Payment of this penalty is not a discharge and does not exclude the calling into question the responsibility of the User for the violation of the rights of the Creator, who will in any case retain the right to request compensation for his entire damage resulting therefrom.
			</p>
			<p>
				Finally, the User is informed that MyBeeDoo is mandated by the Creator to request the deletion of the Media that have been republished in whole or in part by the User on the Platform or on any other platform, social network or medium of any kind.
			</p>
			<p>
				The Creator guarantees that he/she verifies the age of each person appearing on the Media and in particular that each of them is of age, which the Creator must be able to demonstrate at MyBeeDoo's first request.
			</p>
			<p>
				The Creator does not give any guarantee as to the frequency of publication of Media,{" "}
				<strong> their unique character (their unpublished nature)</strong>, or as to their number, which the User expressly accepts and acknowledges.
			</p>
			<p>
				The Creator declares that he holds all the intellectual property rights to the Content sold via these T&Cs.
			</p>
			<p>
				The Creator declares that said Content does not in any way infringe the rights of third parties, in particular that it does not constitute an infringement or an act of unfair or parasitic competition of a pre-existing work and that in no way it infringes the image or intellectual property rights of third parties.
			</p>
			<p>
				In the event of a dispute or complaint related to an Order, the User is invited to consult the T&Cs to find out the procedure to follow and, if necessary, to contact MyBeeDoo at <a href="mailto:support.client@mybeedoo.com">support.client@mybeedoo.com</a>
			</p>
			<p>4°4 Private Content</p>
			<p>
				The User will have the possibility of requesting Private Content from the Creator he/she follows.
			</p>
			<p>
				The User acknowledges that a Private Content is not necessarily a Media exclusively intended for the User but a Media which corresponds to the User's request and which is not freely accessible on any other support or platform.
			</p>
			<p>
				The Creator may therefore reuse any Private Content communicated to the User, provided that it is not made freely accessible, and this, on any medium for a minimum period of two years from its publication on MyBeeDoo. [b]
			</p>
			<p>To request Private Content, the User must:</p>
			<p>Use the Platform's messaging system;</p>
			<p>
				Communicate to the Creator the Specificities of the Private Content he wishes to request, namely the format (photo, video...) or any other aspect of the Media (content, object, aesthetic...).
			</p>
			<p>
				The Creator can also, of his own initiative, propose to the User to view a Media by means of the Messaging.
			</p>
			<p>
				In this case, the Creator undertakes to provide the User with the essential characteristics of the Private Media, in particular its format (photo, video) and its content (content, duration, etc.). The Creator also undertakes to provide the User with the information required under applicable law and to verify its accuracy.
			</p>
			<p>
				The User is informed that he may, at his sole discretion and under his sole responsibility, accept or refuse the Creator's proposal.
			</p>
			<p>4°5 Private Content Price</p>
			<p>The User is informed that the Creator:</p>
			• is under no obligation to respond to his/her request ; <br />
			• may refuse all or part of his request at his sole discretion; <br />
			• will set the price he wishes. <br />
			<br />
			<p>
				The User will be alerted of the Creator's response by email to the email address provided during registration and on the Platform by means of a notification.
			</p>
			<p>
				In the event that the Creator responds favorably to the User's request, it shall offer a prize to the User under its sole responsibility and full control.
			</p>
			<p>
				In case the User accepts the Creator's proposal, he will be asked to pay the agreed price. The commission will also apply to the amount of the Private Media and will remain at 20% (twenty percent) of the amount including all taxes.
			</p>
			<p>
				The Creator will have a period of seventy-two (72) hours from the payment to make the Private Content available to the User by means of the Messaging, in accordance with the User's Specifications.
			</p>
			<p>4°6 Refund of Private Media</p>
			<p>The User may request reimbursement of the Private Content:</p>
			<p>
				In case of failure to communicate the Private Content by the Creator within the time limits provided. In this case, the User must send his request for reimbursement within fourteen (14) days from the maximum delivery date;
			</p>
			<p>
				In the event that the Media does not comply with the Specifications agreed upon between the Creator and the User. In this case, the User must send his request for reimbursement within fourteen (14) days from the date of delivery of the Media.
			</p>
			<p>
				A non-conformity of the private Media with the User's Specifications may be a non-conformity relating to:
			</p>
			• The format of the Media; <br />
			• Its content. <br />
			<br />
			<p>
				In the event that the User formulates a dispute relating to the alleged inadequacy of the Private Content with its Specifications, it must indicate precisely the defects in conformity.
			</p>
			<p>
				MyBeeDoo shall be the sole arbiter of whether the Private Content complies with the Specifications, and its decision shall be binding the Creator and the User.
			</p>
			<p>
				After prior exchanges between the Creator and the User concerned, the request for reimbursement may be made directly to MyBeeDoo by e-mail to the address support.client@mybeedoo.com.
			</p>
			<p>
				If the request is considered to be well-founded, MyBeeDoo may proceed, on behalf of the Creator, to the reimbursement of the entire amount paid by the User for the Private Media.
			</p>
			<p>5° Selling an item</p>
			<p>
				Seller agrees that all items offered for sale are available immediately and that Seller has full ownership and ability to sell. Seller agrees that the items are as described in the description.
			</p>
			<p>
				The Seller certifies that he/she is not in violation of any applicable laws and regulations or infringing on the rights of third parties by offering an item for sale via our services.
			</p>
			<p>
				In his Ad, the Seller undertakes to describe the item for sale in a complete, sincere and accurate manner according to the form proposed by MyBeeDoo. The description of the item must correspond to its actual characteristics. The Seller undertakes to upload only true, accurate and unaltered photographs of the Product, and guarantees to hold all the rights attached to them.
			</p>
			<p>
				MyBeeDoo reserves the right to delete any photographs that it deems to be of insufficient quality or unnecessary for the proper presentation of the item. The Seller certifies that the origin,
				condition and characteristics of the product it offers for sale on our Services are consistent with the description made in the product description and that the item offered for sale is not an infringing item. MyBeeDoo reserves the right to ask the Seller to provide all documents that justify the authenticity of the item offered for sale and/or its origin.
			</p>
			<p>
				Users shall refrain from selling or acquiring directly or indirectly
				through MyBeeDoo items that infringe intellectual property rights
				(counterfeit items) or selective distribution networks. MyBeeDoo
				reserves the right to delete the Profile Sheet and/or the Item Sheet
				of a Seller in the event that he/she does not validly prove of its
				ownership rights over the item(s) listed on our Services and/or the
				origin of the item(s).
			</p>
			<p>
				The User shall not be entitled to claim compensation in the event
				that MyBeeDoo deletes this information for the reasons mentioned
				above. The User shall be solely responsible for the sale of an item
				that is subject to a sales ban and/or that violates the regulations
				in force or infringes the rights of third parties.
			</p>
			<p>
				MyBeeDoo reserves the right to modify the title of the article while
				respecting the short description of the article and the complete
				description of the article in order to optimize the SEO tools linked
				to its platform.
			</p>
			<p>
				Users undertake to inform MyBeeDoo as soon as they notice that an ad
				does not comply with the provisions of this article.
			</p>
			<p>
				The Seller shall determine the selling price of the item under its
				sole responsibility. MyBeeDoo draws the attention of the Seller to
				the fact that it is his responsibility not to set a manifestly
				excessive Price. The price shall include MyBeeDoo 's commission,
				which is 20% of the amount of the item offered for sale, including
				VAT.
			</p>
			<p>
				The Seller shall accurately describe all the different
				characteristics of the Item and shall mention its Price on the Item
				Sheet. There is no minimum or maximum price, however the price of
				the item must include all shipping and insurance costs of the item
				and the commission (20%).
			</p>
			<p>
				Once the Item Sheet is online, the Seller must log in regularly to
				his account in order to see the list of transactions in progress,
				monitor them and keep his inventory up to date. MyBeeDoo reserves
				the right to request the Seller to modify and/or delete its listing
				and/or to do so directly, especially if the Seller has indicated his
				contact information in his ad. The Seller undertakes to publish only
				photos of the Items that he has taken himself. MyBeeDoo reserves the
				right to delete from the site any photo that has not been taken by
				the seller.
			</p>
			<p>6° Transaction and article</p>
			<p>
				All users must be of legal age and capacity to complete a sale or
				purchase.
			</p>
			<p>
				After completion of the Payment process, the Buyer will become party
				to two separate contracts:
			</p>
			<p>
				Contract between the Buyer and the Seller. Buyer and Seller
				acknowledge that the completion of the Payment process by Buyer
				creates a legally binding contract between Buyer and Seller based on
				(i) the description and photographs of the Item, the terms and
				conditions agreed to between Buyer and Seller, and the applicable
				terms and conditions included in these Terms and Conditions. Buyer
				agrees to pay for the Item and Seller agrees to transfer ownership
				of the Item.
			</p>
			<p>
				In order to avoid any ambiguity, it is specified that MyBeeDoo is
				not a party to this agreement
			</p>
			<p>
				Contract between MyBeeDoo and the Buyer relating to the provision of
				the Buyer Protection. The Buyer Protection applies to any purchase
				made using the Buy button on the Site.
			</p>
			<p>
				Shipping Commitment. Once the Total Price has been paid by Buyer,
				MyBeeDoo shall inform Seller that he must ship the Item to Buyer
				within five (5) working days. If Seller fails to ship the Item
				within this time period, MyBeeDoo may cancel the transaction and
				direct the payment service provider to pay the total escrowed price
				to Buyer. Seller is responsible for the proper packaging of the
				Item(s).
			</p>
			<p>
				All transactions will be made through our services. The user will
				have several choices regarding payment and delivery depending on the
				options offered by the sellers involved in the transaction. The
				transaction will be definitively validated only after acceptance of
				the T&Cs, full payment of the order amount and after validation from
				the seller. The prices displayed are published in dollars, including
				all taxes and delivery charges.
			</p>
			<p>
				We cannot be held responsible in case of wrong information
				concerning an item sold by the sellers present on our site. The
				information available on our services is provided as an indication
				only.
			</p>
			<p>
				The Seller undertakes to confirm or deny the order within 72 hours
				following the receipt of the email or SMS from MyBeeDoo informing
				him that the item he has put on sale on our Services has found a
				buyer. The Seller can accept the order by consulting his Account on
				our Services. His acceptance is firm and irrevocable. From the
				acceptance of the seller, the sale is concluded and deemed perfect.
				If the Seller does not respond within 72 hours, the order will be
				cancelled and the sale contract will not be concluded.
			</p>
			<p>
				As soon as the Seller accepts the order, the Buyer accepts that his
				details (name, first name and delivery address) are revealed to the
				Seller. The Seller agrees to keep this information confidential and
				not to disclose it. He also undertakes not to infringe on the
				privacy of other Users and not to send them any message, object or
				documentation, particularly advertising, by any means whatever (in
				particular by mail, telephone or electronic means).
			</p>
			<p>
				If the seller refuses the order, the transaction is cancelled and no
				debit will be made to the Buyer's credit card.
			</p>
			<p>
				Purchases made without using the "Buy" button are made at the Users'
				own risk and MyBeeDoo will not provide any of the services included
				in the Buyer Protection for such transactions. To the extent
				permitted by law, MyBeeDoo is not responsible for purchases made
				outside of the Site and disclaims any liability arising from such
				purchases. For your security and the security of the transactions,
				we invite you to make Transactions using the "Buy" button and not to
				make any Transaction outside the Site.
			</p>
			<p>
				In case of a personalized order, we invite users to contact the
				Seller's internal messaging system to agree on the personalization.
			</p>
			<p>
				For any questions regarding an order, Buyer and Seller may contact
				us at support.client@mybeedoo.com or via our Application in the My
				Account &gt; Contact Us section.
			</p>
			<p>6°1 Default sorting.</p>
			<p>
				When Buyers search for an Item, the ads are sorted by default by
				"relevance". In the Catalog, Buyers can also choose to sort ads by
				price or by date. If the Buyers choose that the advertisements are
				sorted by price and by date, the preferences which they indicated in
				their Account will always be taken into account to classify the
				advertisements.
			</p>
			<p>6°2 Shipping an item</p>
			<p>
				The Seller undertakes to send the ordered Item properly packaged
				within 5 working days following the date of the Order and to
				communicate to the Buyer the tracking number of the carrier as well
				as the photo(s) of the Item and the package before shipping. The
				Seller may contact the Buyer via the messaging system offered by
				MyBeeDoo to obtain additional information for the delivery (e.g.
				telephone number).
			</p>
			<p>
				Buyer's contact information shall be used only for the purpose of
				processing the order and complying with any legal obligations
				related to it. Seller shall retain proof of shipment of the Package
				and a copy of the order invoice for the Item for a period of thirty
				(30) days from such shipment.
			</p>
			<p>
				Seller shall be the only responsible to Buyer for loss, theft or
				damage to the Item or Items shipped.
			</p>
			<p>
				The Buyer undertakes to have a mailbox that complies with the
				recommendations of the postal services (size of the mailbox,
				indication of residents' contact details, etc.). In order to reduce
				the risk of loss or misdelivery during delivery, and to enable the
				tracking of the shipment of the Article (the shipment must be made
				by a carrier that offers tracking of the Package). MYBEEDOO
				recommends that the Seller take out insurance with the chosen
				carrier for the amount corresponding to the price of the Article.
				MyBeeDoo declines all responsibility for delivery, regardless of the
				carrier selected.
			</p>
			<p>
				MyBeeDoo shall not be held responsible for the delivery times, the
				service providers chosen or the quality of delivery chosen by the
				Seller
			</p>
			<p>
				Neither the seller nor MyBeeDoo can be held responsible in case of
				non-delivery of the order by the transport provider due to incorrect
				or incomplete information concerning the delivery address provided
				by the User or in case of force majeure.
			</p>
			<p>
				If the order is not shipped and the Customer Service is not informed
				of the tracking number within seven (7) days after the confirmation
				of the availability of the item, the sale will be cancelled and the
				Buyer will be reimbursed the amount of the order for the item
				concerned.
			</p>
			<p>
				If the Buyer does not receive its Items within thirty (30) days from
				the date of the Order, it may cancel its contract by registered
				letter with acknowledgement of receipt to MYBEEDOO's registered
				office, provided that it has previously requested MyBeeDoo to remedy
				the situation within a reasonable period of time and that delivery
				has not been made within said additional period.
			</p>
			<p>6°3 Payment and wallet</p>
			<p>
				The Seller will receive the amount of the order within 15 calendar
				days from the date of receipt of the product, signature or tracking
				number as proof (legal withdrawal period). The amount received by
				the seller will correspond to the amount of the item(s) sold minus
				the MyBeeDoo commission which is 20% by default. This commission
				corresponds to the operating costs of the site, additional costs and
				remuneration, buyer protection as well as the commission of the
				secure payment provider STRIPE.
			</p>
			<p>
				For example, a buyer places an order of 100€ to a seller, 15 days
				after receiving the order, the seller will receive 80€ directly in
				his wallet. The commission invoice will then be available.
			</p>
			<p>
				The Total Price is held in an Escrow account managed by the payment
				service provider. When a Transaction is completed, the payment
				service provider receives the funds from the Buyer on behalf of the
				Seller and holds these funds in Escrow for disbursement.
			</p>
			<p>
				MyBeeDoo assists its Users by offering them the services provided by
				the payment service provider and provides them with direct
				assistance in connection with these services. It is however
				specified, in order to avoid any ambiguity, that MyBeeDoo does not
				provide any payment processing services to Users. Users are
				responsible for providing accurate contact information for credit
				cards, debit cards and any other payment methods offered on our
				Services. MyBeeDoo disclaims any responsibility for the information
				provided by the User to the fullest extent permitted by applicable
				law.
			</p>
			<p>
				Once the Transaction is finalized, the purchase price of the Item
				and the shipping charges will be immediately transferred by the
				payment service provider to the Seller's e-wallet. The User also has
				the option of requesting the payment service provider to transfer
				the funds stored in the User's e-wallet to the User's personal bank
				account, however, transfer fees may apply depending on the country
				and minimum transfer amount.
			</p>
			<p>
				Transfer of funds held in an eWallet. Users may transfer funds from
				their e-wallet to their bank account. This transfer will be done by
				the payment service provider. To activate this option, the User will
				first need to access their User account settings and enter their
				bank account number, full name and billing address. The User can
				make a payment to a bank account in dollars in one of the countries
				authorized by STRIPE. Payments to bank accounts in other currencies
				and/or outside the European Union/European Economic Area are only
				available under the conditions of the STRIPE payment provider.
			</p>
			<p>
				If for a period of 365 days the funds in a wallet are not
				transferred to a bank account, MyBeeDoo will become the owner of
				this fund.
			</p>
			<p>
				Transfer of funds held in an eWallet. Users may transfer funds from
				their e-wallet to their bank account. This transfer will be done by
				the payment service provider. To activate this option, the User will
				first need to access his User account and enter his bank account
				number, full name and billing address. The User can make a payment
				to a bank account in dollars in one of the countries authorized by
				STRIPE. Payments to bank accounts in other currencies and/or outside
				the European Union/European Economic Area are only available under
				the conditions of the STRIPE payment provider.
			</p>
			<p>
				If for a period of 365 days the funds in a wallet are not
				transferred to a bank account, MYBEEDOO will become the owner of
				this fund.
			</p>
			<p>7 °Returns and refunds</p>
			<p>
				If the Seller requests the return of the item by following the
				return request process, the Buyer shall choose the return shipping
				method and return the Item to the Seller within 5 days. Buyer shall
				pay the cost of returning the Item, unless otherwise agreed with
				Seller. From the moment the Seller is informed by MyBeeDoo that the
				package should have been delivered or seems to be lost, the Seller
				has two (2) days to confirm that Everything is OK with the Item or
				to report a problem:
			</p>
			<p>
				(a) If Seller reports a problem with the returned Item by contacting
				customer service, MyBeeDoo customer service will investigate the
				problem and make a final decision.
			</p>
			<p>
				(b) If the Seller confirms that Everything is OK with the Item or if
				the Seller does not take action within 2 (two) days from the time
				the Seller is informed by MyBeeDoo that the package should have been
				delivered or appears to be lost, the Transaction will be cancelled
				and the Buyer will receive a refund of the Total Price.
			</p>
			<p>
				Indeed, no Order (neither Private Content, nor Lives) can in
				principle give rise to a right of withdrawal, in accordance with
				Article L221-28 of the French Consumer Code, which states that "The
				right of withdrawal may not be exercised for contracts:
			</p>
			<p>
				Of supply of goods made according to the specifications of the
				consumer or clearly personalized;
			</p>
			<p>
				Of supply of a digital content not provided on a material support
				whose execution began after prior express agreement of the consumer
				and express waiver of his right of withdrawal.
			</p>
			<p>
				If MyBeeDoo determines that the Item is Significantly Different from
				the Description, Seller shall have 2 (two) days to take an action
				described in the paragraphs above. If Seller does not take action
				within 2 (two) days, the Transaction will be cancelled and Buyer
				will receive a refund of the Total Price. If MyBeeDoo determines
				that the Item is not Significantly Different from the Description,
				the Transaction will be finalized and the purchase price of the Item
				and shipping charges will be paid to the Seller.
			</p>
			<p>8° Methods of payment</p>
			<p>
				In order to complete a transaction, several payment methods are
				offered to you through our service provider STRIPE. All transactions
				are therefore subject to the Stripe Connected Account Agreement,
				including the Stripe Services Agreement.
			</p>
			<p>
				By agreeing to these Terms of Service or continuing to operate as a
				Seller on our Services, you agree to be bound by the Stripe Terms of
				Service, as they may change from time to time.
			</p>
			<p>
				The banking data collected during the payment are protected and
				encrypted by this provider, neither MyBeeDoo nor the sellers have
				access to these data. In case of refusal of payment by the provider
				Stripe, the transaction will be cancelled. Until full payment of the
				order, the Items remain the property of the sellers.
			</p>
			<p>
				The User certifies on his honor to be able to use the means of
				payment as well as the veracity of his personal data. The Buyer can
				pay by : Credit card (Visa, Mastercard or American Express) via our
				secure provider Stripe which uses the SSL (Secure Socket Layer)
				protocol, encrypting by software the information transmitted and
				ensuring that no third party (nor MyBeeDoo) can take cognizance of
				it during transport on the network.
			</p>
			<p>
				MyBeeDoo may verify with the Buyer, by telephone, e-mail or postal
				mail, to validate the order and request proof of identity (copy of
				identity card and/or proof of residence). Pending receipt of these
				documents, MyBeeDoo reserves the right to suspend or cancel an
				order. MyBeeDoo cannot be held responsible in case of fraudulent use
				of the means of payment used during one or more orders.
			</p>
			<p>
				Saving the means of payment, such as saving the bank card offered by
				our service provider Stripe, allows you to place an order more
				quickly and easily, while offering a maximum level of security. To
				do so, the Buyer must check the box "I authorize Stripe to store my
				financial information so that I do not have to enter it when I place
				future orders with this merchant". These bank details will not be
				kept by MyBeeDoo but by Stripe.
			</p>
			<p>8°1 Refusal of payment </p>
			<p>
				If the bank refuses to debit a card or other means of payment, the
				User shall contact the Platform's Customer Service Department in
				order to pay for the Order by any other valid means of payment
				accepted by the Creator.
			</p>
			<p>
				In the event that, for any reason whatsoever, such as opposition,
				refusal or other, the transmission of the money flow owed by the
				User turns out to be impossible, the Order shall be cancelled and
				the sale or subscription automatically cancelled.
			</p>
			<p>9° Receipt of Order</p>
			<p>
				The Buyer must confirm receipt of the Item and its compliance within
				48 hours of receipt of the Item (including weekends and holidays,
				not taking into account the day of delivery). If the Buyer has not
				notified MyBeeDoo within 48 hours of receipt of the item and its
				conformity, MyBeeDoo shall proceed with the finalization of the sale
				and the payment to the Seller of the amounts corresponding to the
				transaction. The Buyer and the Seller accept that MyBeeDoo
				calculates this 48-hour period from the information provided by the
				carrier. MyBeeDoo declines any responsibility in case of wrong
				information provided by the carrier or the Seller.
			</p>
			<p>
				In the case of an apparent anomaly (damage, missing items, broken
				package, broken items, etc.), the Buyer must imperatively refer to
				the delivery order of the specific reserves on the delivery slip:
				indication of the number of packages and/or missing and/or damaged
				items and detailed description of the possible damage (open or torn
				packaging, damaged or missing items, etc.). It is essential that the
				Buyer keeps the elements of the shipment in the state in which they
				were delivered (accessories, parts received with these Articles in
				their original state). We invite the Buyer to take a picture of the
				package upon receipt to justify the opening of the dispute.
			</p>
			<p>
				In the case of failure to be present at the time of delivery or to
				pick up the Article, and that the package is returned to the Seller
				by the carrier, the Buyer agrees that the shipping costs paid by the
				Seller as well as any other tax, fee or commission, will be fully
				refunded to the Seller. Seller agrees to notify MyBeeDoo and Buyer
				as soon as possible of the return of the Item. The Seller undertakes
				to inform the Buyer whether the refund of the returned Article, net
				of any tax, fee or commission, can be made. The Article (excluding
				shipping costs and taxes and fees) will be refunded.
			</p>
			<p>
				If the Package is indicated as "delivered" but Buyer claims not to
				have received it, Buyer agrees to file a claim with the carrier
				and/or to provide any information requested by the carrier in order
				to enforce the shipping insurance contracted by Seller.
			</p>
			<p>
				In the event that the package is lost by the postal services, Seller
				agrees to refund the Transaction Price to Buyer. It is the
				responsibility of the Seller to take action against its carrier.
			</p>
			<p>
				The average duration of an investigation is thirty (30) days. If
				loss or damage is found, MYBEEDOO will refund the Buyer. This refund
				will be made within five (5) business days from the confirmation of
				the loss or damage of the Package by the carrier. In the event that
				the Package is wrongly declared "received by the Buyer", it is up to
				the Buyer to initiate a dispute procedure. To do so, the Buyer must
				contact the Customer Service of the carrier within two (2) days
				following the last update of the status of the Package.
			</p>
			<p>
				The Seller undertakes to communicate to the Buyer all information
				necessary for the opening of a dispute or litigation with the
				carrier. MyBeeDoo provides the Buyer and the Seller with a messaging
				service in order to facilitate exchanges for all disputes, claims,
				personalization.
			</p>
			<p>
				MyBeeDoo cannot be held responsible for the loss, theft, delay,
				deterioration or damage in the routing of the Items.
			</p>
			<p>
				In case of a dispute between the Seller and the Buyer, MyBeeDoo may
				block the funds paid by the Buyer until the dispute is resolved.
			</p>
			<p>
				In case of a transaction you are invited to rate and leave comments
				about the sellers as well as the Items or services purchased through
				our services. These comments must not in any way interfere with the
				law or be in bad faith. Any comments that are racist, illegal,
				abusive, defamatory, harassing or irrelevant will be deleted.
				MyBeeDoo reserves the right to delete any comment without prior
				notice or reason.
			</p>
			<p>
				In the event of non-compliance with these terms and conditions of
				use, we reserve the right to suspend access temporarily or
				permanently to the services to the User concerned. All Users of the
				site certify that they are at least 18 years old and legally capable
				(e.g., without guardianship or trusteeship).
			</p>
			<p>10° Content of the Site and Falsification</p>
			<p>
				Any downloading, reproduction, use, display or modification of the
				content of our site must be the subject of a written request to
				MyBeeDoo. The photos, images, texts, links, videos, trademarks,
				logos, or any other content appearing on our services are the
				property of MyBeeDoo and/or its content creators.
			</p>
			<p>
				The User authorizes MyBeeDoo, by putting his Profile and/or a
				Product online, to retouch the photos of the Articles he has put
				online in order to optimize their visibility.
			</p>
			<p>To communicate on certain Items that he has put on sale.</p>
			<p>
				To modify, save, reproduce, distribute and exploit all elements,
				including the title of the item, its image and/or photos of the
				Items, and/or to put comments online, in any format, by any means
				and on any medium, in particular on our Services and on commercial
				documents such as "Notifications" and "Newsletters", as well as on
				MyBeeDoo partner sites.
			</p>
			<p>
				The User undertakes not to use our Services, in particular through
				comments, in an abusive or defamatory manner or in a way that may
				damage the reputation of other Users. In the event of failure to
				comply with this obligation, MyBeeDoo reserves the right to
				permanently close the User's account. The User shall refrain from
				publishing photographs with inappropriate content (nudity, violence,
				hatred, etc.). MyBeeDoo reserves the right to delete any content
				that it deems inappropriate or defamatory, and also to delete Users'
				accounts.
			</p>
			<p>11°Interaction and messages on our services</p>
			<p>11°.1 Private messages</p>
			<p>
				The exchange of Private Messages between users must not have as
				object:
			</p>
			
				• messages or information constituting advertising (except as
				permitted by the Forum Rules); <br />
			
			 	• spam or content carrying viruses. <br />
				<br />
			<p>
				direct mail messages of any other nature (where a message is sent to
				more than five Users or where the same message is copied and sent to
				Users who have not requested to receive it)
			</p>
			<p>
				messages containing texts contrary to public morality and safety,
				inappropriate, insulting or defamatory messages or messages in any
				other way considered incompatible with the present General
				Conditions and with the interests of the Users
			</p>
			<p>
				messages of an illicit nature or otherwise likely to cause harm to
				other Users and/or the Site
			</p>
			<p>messages likely to constitute any form of harassment</p>
			<p>
				MyBeeDoo may use <strong>automated</strong> software and algorithms
				to detect the content of Private Messages that are contrary to
				public morality and safety or that indicate fraudulent behavior.
			</p>
			<p>
				If <strong>automated</strong> software detects such content in a
				Private Message, and without prejudice to any other remedy available
				to MyBeeDoo, the Private Message and the User may be blocked and/or
				the Private Message may be hidden from the User to whom it was sent
				and the User who sent the Private Message may receive a warning
				reminding him/her of his/her obligation to comply with these General
				Terms and Conditions and be informed that MyBeeDoo may take other
				measures such as suspending the User's Account in the event of
				further violations. MyBeeDoo's use of this{" "}
				<strong>automated</strong> software shall not be interpreted as a
				commitment to monitor or an obligation to search for illegal
				activities and/or content on our Services and, to the extent
				permitted by applicable laws, shall not incur the liability of
				MyBeeDoo.
			</p>
			<p>12° Damage and safety</p>
			<p>
				We assume responsibility for damage caused by gross negligence or
				intentional misconduct on our part or willful misconduct on our
				part, including our Directors and Legal Representatives. We also
				assume liability for damages caused by our breach of an essential
				obligation of these conditions of these Terms and Conditions and
				upon which you relied when registering for our MyBeeDoo Services. In
				this case, we shall only be liable for losses and damages that were
				predictable to you and MyBeeDoo. This paragraph does not affect your
				statutory rights as a consumer. It does not limit MyBeeDoo's
				liability resulting from injury to life, limb or health, breach of
				warranty or fraudulently concealed defect. The Terms of Use and
				General Terms of Sale also apply.
			</p>
			<p>
				In accordance with applicable laws and regulations, MyBeeDoo is
				committed to taking all appropriate measures to protect the security
				of data provided by Users and to prevent our Services from
				compromising data and software stored on User's computers, tablets
				or smartphones. However, even though MyBeeDoo has taken appropriate
				measures in compliance with legal requirements, its systems may
				still be compromised by unpredictable events such as cyber-attacks
				or security breaches regarding data transmission or affecting the
				volume and rate of data transmissions. Under these conditions, we
				strongly suggest that Users take all appropriate measures to protect
				their own data and/or software, in particular against any
				contamination by viruses circulating on the Internet.
			</p>
			<p>
				Users agree not to, and Visitors agree not to: collect, hold,
				transmit to third parties, make public, publish or disclose data
				relating to Users of the Site or activities of Users of the Site,
				including Transactions, their number, type, price, etc., if such
				data has become available unlawfully or as a result of an unlawful
				act or omission.
			</p>
			<p>
				Users agree not to, and Visitors agree not to, collect, hold,
				transmit to third parties, make public, publish or disclose any
				information that appears on our Services, if doing so would affect
				the rights of other Users, including, but not limited to,
				intellectual property rights or privacy rights. This restriction
				does not apply to the "sharing" feature on our Services that allows
				Users to share public information available on our Services on
				MyBeeDoo's Social Network Accounts or on their own social network
				accounts, as well as to send such information to each other or to
				others by e-mail.
			</p>
			<p>
				12.1 The User commits himself to keep his login and password
				confidential and not to disclose them to third parties (except to
				persons whom the User authorizes to use his login and password). The
				User is solely responsible (to MyBeeDoo and others) for all
				activities that occur under his Account. If a User learns or
				suspects that an unauthorized third party knows his or her password
				or has accessed his or her Account, he or she must notify MyBeeDoo
				as soon as possible via the contact form.
			</p>
			<p>
				12°2 The User undertakes to modify immediately any information
				appearing on our Services which would not be up to date or exact,
				including the information provided by the User at the time of his
				registration on our Services and the information on the Account of
				the User, as well as the information on the Articles referenced in
				the Catalog.
			</p>
			<p>12°3 By using our Services, the User declares:</p>
				• that he is a natural person, that he is 18 years of age or older
				(failing which, the parent(s) or legal guardian(s) must register and
				supervise the minor's use of the Site); <br />
				• that he is using the Site's Services only for his personal benefit
				and not for the purpose of a professional activity, and (iii) that
				he has full capacity to make Transactions on our Services and is
				fully empowered to do so; <br />

				• he understands that he is fully responsible, in accordance with
				these Terms and Conditions and applicable law, for the performance
				of his obligations under the contract between Buyer and Seller, as
				defined in Section 4.1.1 of the Terms and Conditions; <br />

				• understand that when he orders an Item, he undertakes to purchase
				that Item and that in the event of non-performance of that
				Transaction, he may be obliged to compensate the Seller for any
				losses incurred by the Seller; <br />
			
				• understand that it must pay to MyBeeDoo the Buyer Protection fee
				for each Transaction made using the Buy button; <br />
			
				• understand that it must pay MyBeeDoo if it decides to purchase
				Additional Services. <br />
				<br />
			
			<p>13° Force Majeure</p>
			<p>
				We shall not be held responsible for any delay or failure to fulfil
				our obligations under these Terms if such delay or failure is caused
				by force majeure.
			</p>
			<p>14° Cookies</p>
			<p>
				Cookies are small files stored in the browser while using a site to
				track the User's movements while browsing that site to understand
				preferences, to remember the login credentials, to resume where the
				User left off, or otherwise customize the site. Cookies on our site
				are not used to reveal your identity or any other personally
				identifiable information.
			</p>
			<p>
				By managing cookies from the browser, the User can allow the
				targeting of advertisements to be stopped if they wish. However, by
				deleting cookies.
			</p>
			<p>Access will be limited to the basic functions of the site.</p>
			<p>
				If the User deletes the storage of Cookies, or deletes those stored
				in them, the User will no longer be able to benefit from certain
				functionalities necessary to navigate in certain areas of the Site
				or Applications.
			</p>
			<p>
				The protection of your data is essential for us. The requests marked
				with an asterisk are mandatory in order to allow you to continue
				browsing our site without restriction. They also allow us to
				register you to our Newsletter, to create your account, to finalize
				an order, to propose you targeted commercial offers, to fight
				against fraud, to elaborate statistics, studies and surveys,
				satisfaction survey, in order to improve our site and our database.
				These data are also essential to us during the management of your
				account, delivery, return, refund, billing:
			</p>
			• your identity (name, first name, age) <br />
			
				• your contact information (delivery address, billing address,
				telephone number, e-mail address)
			<br />
			• your payment data (protected by our service provider STRIPE) <br />
			• your web coordinates (IP address) and geolocation <br />
			• commercial prospecting (acceptance or refusal) <br />
			• your navigation on our Services (preferences, opening, click)
			consent to the use of the data. <br />
			<br />
			<p>
				Some data may be provided via social networks you have connected to
				our site. Through your activity on our services, we may also collect
				the following data:
			</p>
			• a history of exchanges between MyBeeDoo and you <br />
			• a history of your orders and sales via our services <br />
			• a history of financial or accounting transactions made through our services<br />
			• a history of your activity on the application or our Services	(including visits and content generated in connection with your account <br />
			• if you have responded to our questionnaires, the details of your responses.<br />
			<br />

			<p>15° Personal data</p>
			<p>
				In accordance with the French law "Informatique et Libertés" n°78-17
				of January 6, 1978, the User may at any time access the personal
				information concerning him/her that he/she has communicated to the
				Site, and/or request its rectification, completion, clarification,
				updating or deletion by contacting the Customer Service via the
				heading My Account &gt; Contact Us by email
				support.client@mybeedo.com or by simple letter (SAS HAKA, 82 allée
				du petit pont, 83240 CAVALAIRE), indicating his/her name, first
				name, date and place of birth, postal and email address as well as
				his/her login. Our Services www. mybeedoo.com is designed to be very
				attentive to the needs of its Users.
			</p>
			<p>
				By registering on our Services, the User acknowledges and agrees to
				the processing of his/her personal data by MyBeeDoo in accordance
				with applicable law and the stipulations of the General Terms of
				Use. All such data that you enter is secured and encrypted to
				preserve your security.
			</p>
			<p>
				For legal reasons, the data is kept for three years from the last
				commercial exchange and then archived or rendered anonymous for
				statistical purposes. If you do not wish to receive any further
				advertising, you must send a letter to MyBeeDoo 's head office.
			</p>
			<p>
				Only the services authorized by MyBeeDoo have access to this data
				according to their degree of authorization:
			</p>
			• MyBeeDoo management (management of disputes, modification, or deletion of data ...) <br />
			• the sales department (competitions, prospecting, recommendations)	<br />
			• the digital and technical provider (hosting, computer technology...)<br />
			• Payment provider (order completion, refunds ...)<br />
			• Customer relations section (surveys, statistics, opinions ...)<br />
			<br />
			<p>
				All persons internal or external to MYBEEDOO who will use, collect
				or process this data will be subject to a contractual obligation.
			</p>
			<p>16° Disputes and account blocking</p>
			<p>
				Our Services act only as an intermediary, MyBeeDoo is in no way a
				reseller of the Items offered for sale and does not become, at any
				time, the owner of the Items.
			</p>
			<p>
				The quality control carried out by MyBeeDoo shall only concern the
				suitability of the Item and its description inserted by the Seller
				in the Item file. MyBeeDoo shall not be held responsible for any
				disputes arising between the Buyer and the Seller concerning a
				transaction and cannot guarantee that the Item will satisfy the
				Buyer
			</p>
			<p>
				When a dispute arises between the parties, MyBeeDoo may, for a
				period of 30 days, make every effort to help the parties reach an
				amicable agreement and act as a mediator, but shall not be held
				responsible for the outcome of the dispute. During this period, the
				transaction funds of the User(s) concerned may be temporarily
				blocked and transfer requests suspended
			</p>
			<p>
				Any behavior revealing a clear intention to mislead and/or bad
				faith, may be subject to a formal notice, suspension or permanent
				interruption of the User's access to MyBeeDoo services. In this
				case, the Seller shall reimburse MyBeeDoo for any sums it may have
				received from the sale of the said Item and shall compensate
				MyBeeDoo for any costs incurred by MyBeeDoo and/or the Buyer as a
				result of the Seller's activity and shall indemnify MYBEEDOO against
				any legal proceedings.
			</p>
			<p>
				MyBeeDoo is in partnership with providers whose websites are linked
				to our services. MyBeeDoo cannot be held responsible for the content
				of these sites nor can it guarantee the satisfaction of their
				services or articles in case of transaction.
			</p>
			<p>
				Immediate blocking. Notwithstanding the foregoing, MyBeeDoo may
				restrict and/or block the User's account with immediate effect and
				without prior notice or formal notice in the following cases:
			</p>
			<p>
				Account of a User reported by the payment service provider. The
				Payment Service Provider may report to MyBeeDoo any User Account if
				the Payment Service Provider has serious reason to suspect that, by
				using payment services provided by the Payment Service Provider,
				such User has violated applicable laws and/or the Payment Service
				Provider's terms and conditions and/or misused our Services
				(including, but not limited to, illegal sales on our Services, money
				laundering, fraud, identity theft, or use of false documents).
			</p>
			<p>
				Litigation initiated by the User with a third-party payment service
				provider regarding a payment. If a User initiates a dispute
				regarding a Transaction with a third-party payment service provider
				and there are objective and legitimate reasons to believe that, in
				this context, the User has violated applicable laws, breached these
				Terms and Conditions and/or misused the Site, MyBeeDoo may
				immediately restrict and/or block the User's Account (including
				suspending and/or cancelling pending Transactions).
			</p>
			<p>
				Violation of Applicable Laws, Public Safety or User Safety. The
				User's use of the Services or the Content posted by the User clearly
				violates any law, regulation or rule relating to public safety or is
				likely to have serious consequences for the health, safety or
				legitimate economic interests of MyBeeDoo, other Users, Professional
				Users or third parties. For example:
			</p>
			• the User has uploaded to the Content of the Site contents that glorify crimes against humanity, incite racial hatred and/or violence or are of a child pornographic nature; <br />
			• the User has impersonated another User or another person.< br/>
			<br />
			<p>
				There is objective and corroborating evidence that fraud of any kind
				has been or is being committed by a User or that a User is misusing
				the Platform in a manner that jeopardizes the safety of other Users
				or the Platform, including, but not limited to, logging in from
				suspicious IP addresses, scamming other Users, or sending spam to
				other Users. MyBeeDoo undertakes to investigate diligently any
				fraudulent practice and to examine promptly and seriously any
				request from the suspected User
			</p>
			<p>
				Regulatory obligation or compelling legal reason, where compliance
				with a notice period would violate any law, regulation or
				instruction from a judicial enforcement authority, or would
				otherwise risk engaging the liability of MYBEEDOO or its affiliates.
			</p>
			<p>
				Computer Security Risk, where a risk threatens the security and
				technical functioning of MYBEEDOO's computer systems.
			</p>
			<p>
				Repeated or serious violations, when the User has committed repeated
				violations of these General Conditions, the Catalog Rules or the
				Forum Rules.
			</p>
			<p>17° Applicable Law</p>
			<p>
				We reserve the right to restrict, suspend or remove access to our
				services to any User who has violated these terms and conditions of
				use or who has engaged in fraudulent behavior against MYBEEDOO or
				any third-party vendor of the MyBeeDoo site.
			</p>
			<p>
				We also reserve the right to take any legal action, complaint or any
				other sanction that the management of MyBeeDoo deems useful and
				necessary, against any fraudulent user or having committed a
				violation of the general conditions of use or sale, for
				reimbursement or compensation for the damage suffered.
			</p>
			<p>
				These general conditions of use are governed by French law. Any
				dispute relating to these terms and conditions shall be subject to
				the jurisdiction of the French courts. If any part of these terms
				and conditions of use and sale is found to be illegal, invalid or
				inapplicable, for any reason whatsoever, the provisions in question
				shall be deemed unwritten, without affecting the validity of the
				other provisions which shall continue to apply to all users of our
				services and to MyBeeDoo.
			</p>
			<br />
			<br />
			<p>
				For any claims, please contact the customer service at:
				service.client@mybeedoo.com.
			</p>
        </Form.Label>
      </Form.Group>
    </Form>
  );
};
export default CGU;
