import {
    LOGIN_SUCCESS,
    LOGOUT,
    DETAILS_USER_CURRENT,
} from '../actions/actionType';

const initialState = {
    acceptedCondition : false,
    userConnected: null,
    isConnected: false,
    detailsUserCurrent: null,
};

const compteReducers = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                userConnected: action.data.user,
                isConnected: true,
            };
        case LOGOUT:
            return {
                ...state,
                userConnected: null,
                isConnected: false,
            };
        case DETAILS_USER_CURRENT:
            return {
                ...state,
                detailsUserCurrent: action.data.detailsUserCurrent,
            };
        case "ACCEPT_CONDITION":
            return {
                ...state,
                acceptedCondition : action.data    
            };
        default:
            return state;
    }
};

export default compteReducers;