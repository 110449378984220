import {
    INCREMENT_NOTIFICATION_COUNT,
    RESET_NOTIFICATION_COUNT,
    RESET_TOTAL_COUNTER,
    SET_NOTIFICATION_COUNT,
    SET_ORDERS_COUNTER_ADD,
    SET_ORDERS_COUNTER_REMOVE,
    SET_TOTAL_COUNTER,
    SET_MESSAGE_COUNT,
    UPDATE_NEW_ITEMS_COUNT,
    SET_ORDERS_COUNT,
    INCREMENT_MESSAGE_COUNT,
    LOGOUT
} from '../actions/actionType';

let initialState = {
    newItemsCount: 0,
    notificationCount: 0,
    messageCount: 0,
    ordersCount: 0,
    requestFrienCount: 0,
    totalCount: 0
};

const counterReducers = (state = initialState, action) => {
    const { newItemsCount, notificationCount, totalCount, messageCount } = state;
    switch (action.type) {
        case SET_ORDERS_COUNTER_ADD:
            return {
                ...state,
                newItemsCount: newItemsCount + 1,
            };
        case SET_ORDERS_COUNTER_REMOVE:
            return {
                ...state,
                newItemsCount: newItemsCount - 1,
            };
        case UPDATE_NEW_ITEMS_COUNT:
            return {
                ...state,
                newItemsCount: action.data,
            };
        case SET_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.data
                // notificationCount: notificationCount + 1
            }
        case SET_MESSAGE_COUNT:
            return {
                ...state,
                messageCount: action.data
            }
        case SET_ORDERS_COUNT:
            return {
                ...state,
                ordersCount: action.data
            }
        case INCREMENT_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: notificationCount + 1
            }
        case INCREMENT_MESSAGE_COUNT:
            return {
                ...state,
                messageCount: messageCount + 1
            }
        case RESET_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: 0
            }
        case SET_TOTAL_COUNTER:
            return {
                ...state,
                // totalCount: totalCount + action.data
                totalCount: action.data
            }
        case RESET_TOTAL_COUNTER:
            return {
                ...state,
                totalCount: 0
            }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default counterReducers;