import {
	GET_ALL_DISCUSSION,
	MESSAGE_RECEIVED,
	REFRESH_DISCUSSION,
	SET_MESSAGES,
	SET_SELECTED_DISCUSSION,
	SET_SOCKET_CONNECTED,
	SET_UPDATE_DISCUSSION,
} from '../actions/actionType';

const initialState = {
	messages: [],
	newMessages: 0,
	discussions: [],
	selectedDiscussion: null,
	updateDiscussion: false,
	refreshDiscussion: false,
	socketConnected: false
};

const chatReducers = (state = initialState, action) => {
	switch (action.type) {
		case MESSAGE_RECEIVED:
			const { messages, newMessages, selectedDiscussion } = state;

			if (
				selectedDiscussion &&
				selectedDiscussion.discussion.id === action.data.id
			) {
				messages.push({
					id: action.data.lastMessage.id,
					img: action.data.lastMessage.sender.profil,
					time: action.data.lastMessage.createdAt,
					content: action.data.lastMessage.message,
					files: action.data.lastMessage.files,
					isSentByCurrentUser: false,
				});
				// messages.push(action.data.lastMessage)
			}
		
			return {
				...state,
				newMessages: newMessages + 1,
				messages: messages,
			};
		case GET_ALL_DISCUSSION:
			return {
				...state,
				discussions: action.data,
			};
		case SET_MESSAGES:
			return {
				...state,
				messages: action.data,
			};
		case SET_SELECTED_DISCUSSION:
			return {
				...state,
				selectedDiscussion: action.data,
			};
		case SET_UPDATE_DISCUSSION:
			return {
				...state,
				updateDiscussion: action.data.updateDiscussion,
			};
		case REFRESH_DISCUSSION:
			return {
				...state,
				refreshDiscussion: action.data
			}
		case SET_SOCKET_CONNECTED:
			return {
				...state,
				socketConnected: action.data
			}
		default:
			return state;
	}
};

export default chatReducers;
