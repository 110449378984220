import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { stripePaymentMethodHandler } from "../../services/stores/actions/orders";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { setPaniersCounterReset } from "../../services/stores/actions/panier";
import { useHistory } from "react-router-dom";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "27px",
      color: "#212529",
      fontSize: "1.1rem",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CheckoutForm(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [adresseClient, setAdresseClient] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setAdresseClient(props.adresseClient);
  }, [props.adresseClient]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setErrorMsg("");

    const paymentMethodObj = {
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email,
      },
    };

    if (props.dataDiscussion) {
      let dataParsed = JSON.parse(props.dataDiscussion);
      let response = {};
      response.data = dataParsed.data_pi ? dataParsed.data_pi : dataParsed;
      // response.data = "pi_3MzG55DmyHx2lsux3wUmLW8F_secret_ypRzXvSo4oH2FygH7geMaOghH";
      stripe
        .confirmCardPayment(response.data, {
          payment_method: paymentMethodObj,
        })
        .then((response) => {
          history.push({
            pathname: "/ValidationPaymentCard",
            state: {
              status: response.error
                ? "Error"
                : response?.paymentIntent?.status,
            },
          });
          props.setPaniersCounterReset();
          props.handleClose();
        });
    } else {
      const paymentMethodResult =
        await stripe.createPaymentMethod(paymentMethodObj);

      //NOTE: Création de commande
      stripePaymentMethodHandler(
        {
          result: paymentMethodResult,
          amount: props.amount || "100",
          client: adresseClient || "adresse",
        },
        handleResponse
      ).then((response) => {
        //#BUG: Diminue stock de produit et supprime les produits dans le panier
        stripe
          .confirmCardPayment(response.data, {
            payment_method: paymentMethodObj,
          })
          .then(
            (response) => {
              history.push({
                pathname: "/ValidationPaymentCard",
                state: {
                  status: response.error
                    ? "Error"
                    : response?.paymentIntent?.status,
                },
              });
              props.setPaniersCounterReset();
            },
            (error) => {
              // console.log(`Error`, error);
            }
          );
        //BUG#
      });
    }
  };

  const handleResponse = (response) => {
    setLoading(false);
    if (response?.error) {
      setErrorMsg(
        typeof response.error === "string"
          ? response.error
          : response.error.message
      );
      return;
    }
    props.setPaymentCompleted(response?.success ? true : false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-name">Card holder</label>
            <input
              id="cc-name"
              type="text"
              className="form-control rounded-pill"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-email">Email</label>
            <input
              id="cc-email"
              type="text"
              className="form-control rounded-pill"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mb-3">
            <label htmlFor="cc-number">Card number</label>
            <CardNumberElement
              id="cc-number"
              className="form-control rounded-pill"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-7 col-md-6 mb-3">
            <label htmlFor="expiry">Card expiry</label>
            <CardExpiryElement
              id="expiry"
              className="form-control rounded-pill"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
          <div className="col-5 col-md-6 mb-3">
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              className="form-control rounded-pill"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </div>

        <hr className="mb-4" />
        <button
          className="btn w-100 rounded-pill mb-5"
          style={{ background: "#E0C03B" }}
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            ></div>
          ) : (
            `PAYMENT`
          )}
        </button>
        {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
      </form>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    paniersCount: state.paniersReducers.paniersCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaniersCounterReset: (data) => {
      return dispatch(setPaniersCounterReset(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
