import { toast } from "react-toastify";

const ErrorNotification = (error) => {
	let message = "";
	// switch (error) {
	// 	// Error Inscription Step 3
	// 	case "inscription.error.email_exist":
	// 		message = "Email déjà utilisé. Veuillez utiliser un autre";
	// 		return toast.error(message);
	// 	case "inscription.error.pseudo_exist":
	// 		message = "Pseudo déjà utilisé. Veuillez utiliser un autre";
	// 		return toast.error(message);
	// 	default:
	// 		break;
	// }
	switch (error) {
		// Error Inscription Step 3
		case "inscription.error.email_exist":
			message = "Email already used. Please use another";
			return toast.error(message);
			case "inscription.error.telephone_exist":
				message = "Phone number already used. Please use another";
				return toast.error(message);		
		case "inscription.error.pseudo_exist":
			message = "Nickname already used. Please use another";
			return toast.error(message);
		default:
			break;
	}
};

export default ErrorNotification;
