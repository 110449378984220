const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }

  // if ('serviceWorker' in navigator) {
	// 	window.addEventListener('load', () => {
	// 		navigator.serviceWorker
	// 			.register('/custom-service-worker.js')
	// 			.then((registration) => {
	// 				console.log('Service Worker enregistré avec succès:', registration);
	// 			})
	// 			.catch((registrationError) => {
	// 				console.log(
	// 					"Erreur lors de l'enregistrement du Service Worker:",
	// 					registrationError
	// 				);
	// 			});
	// 	});
	// }
};

export default reportWebVitals;
