import { api } from '../../../res/constants';
import { INCREMENT_NOTIFICATION_COUNT, RESET_NOTIFICATION_COUNT, SET_NOTIFICATION_COUNT, SET_MESSAGE_COUNT, SET_ORDERS_COUNT,SET_TOTAL_COUNTER, INCREMENT_MESSAGE_COUNT, UPDATE_NOTIFICATION } from './actionType';
import WS from './api';

export const getRecentActivities = (
    paginationPage = 1,
    paginationPerPage = 15
) => {
    const url = api.activities;

    return new Promise((resolve, reject) => {
        WS.axiosSecureGet(
            `${url}?pagination.page=${paginationPage}&pagination.par_page=${paginationPerPage}&order.ascending=false`
        ).then(
            (response) => {
                // console.log('CONTENTS ACTIVITIES= ', response);
                resolve(response.data.data);
            },
            (error) => {
                console.log('ERROR ', error);
                reject(error);
            }
        );
    });
};


export const getRecentNotification = (
    paginationPage = 1,
    paginationPerPage = 15
) => {
    return new Promise((resolve, reject) => {
        WS.axiosSecureGet(`${api.notifications}?pagination.page=${paginationPage}&pagination.par_page=${paginationPerPage}&order.ascending=false`)
            .then((response) => {
                console.log('Response : ', response);
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const getCounter = () => {
    return new Promise((resolve, reject) => {
        WS.axiosSecureGet(`${api.notifications}/not-seen`)
            .then((response) => {
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const markAllNotificationAsRead = () => {
    return new Promise((resolve, reject) => {
        WS.axiosSecureGet(`${api.notifications}/all-seen`)
            .then((response) => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            })
    })
}

export const markNotificationAsRead = (id) => {
    return new Promise((resolve, reject) => {
        WS.axiosSecureGet(`${api.notifications}/${id}/seen`)
            .then((response) => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            })
    })
}


// ACTIONS
export const setCouterNotification = (data) => {
    return {
        type: SET_NOTIFICATION_COUNT,
        data
    }
}
export const setCouterMessage = (data) => {
    return {
        type: SET_MESSAGE_COUNT,
        data
    }
}
export const setCouterOrder = (data) => {
    return {
        type: SET_ORDERS_COUNT,
        data
    }
}
export const incrementCouterMessage = (data) => {
    return {
        type: INCREMENT_MESSAGE_COUNT,
        data
    }
}

export const incrementNotificationCount = () => {
    return {
        type: INCREMENT_NOTIFICATION_COUNT
    }
}

export const removeCounterNotification = () => {
    return {
        type: RESET_NOTIFICATION_COUNT
    }
}

export const setTotalCounter = (total) => {
    return {
        type: SET_TOTAL_COUNTER,
        data: total
    }
}