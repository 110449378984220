import {
	SHOW_MODAL_COMMENTAIRE,
	GET_PARENT_ID,
	GET_IS_COMMENT,
	GET_COMMENT_COUNT,
} from '../actions/actionType';

const initialState = {
	id: null,
	parentId: null,
	state: false,
	isCommented: false,
	commentCount: null,
};

const ModalReducers = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_MODAL_COMMENTAIRE:
			return {
				...state,
				id: action.data.id,
				parentId: action.data.parentId,
				state: action.data.state,
			};
		case GET_PARENT_ID:
			return {
				...state,
				parentId: action.data.parentId,
			};

		case GET_IS_COMMENT:
			return {
				...state,
				isCommented: action.data.isCommented,
			};
		case GET_COMMENT_COUNT:
			return {
				...state,
				commentCount: action.data.commentCount,
			};
		default:
			return state;
	}
};

export default ModalReducers;
