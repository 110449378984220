import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import user_add from '../../assets/profil/user_add.svg';
import trash from '../../assets/trash.svg';
import logoBee from '../../assets/logoBee.png';
import ReactLoading from 'react-loading';
import {
	friendRequestRefuse,
	friendRequestValidate,
} from '../../services/stores/actions/compte';

export function ModalMessage(props) {
	const [show, setShow] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<button
				// className="btn btn-sm btn-rounded k-regular-13"
				style={{
					fontFamily: 'KohinoorRegular',
					border: 'solid 1px #F7CC2A',
				}}
				onClick={handleShow}>
				{props.label}
			</button>

			<Modal
				show={show}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				{/* {showLoading && (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							left: '44%',
							zIndex: 999,
						}}
						className="w-50 text-center">
						<ReactLoading
							loader={<img src={logoBee} alt=""/>}
							color={'#e8cc54'}
							height={'20%'}
							width={'20%'}
						/>
					</div>
				)} */}
				<Modal.Header className="row mx-0 justify-content-center">
					<img src={user_add} width={`50px`} height={`50px`} alt="" />
					<p className="text-center k-semibold-20 w-100 pt-2">{props.title}</p>
				</Modal.Header>
				<Modal.Footer>
					<div className="col-12">
						{props.status === 'REFUSED' && (
							<button
								className="btn btn-yellow btn-rounded k-regular-15 mr-2 btn-block"
								style={{ border: 'none' }}
								onClick={() => {
									setShowLoading(true);
									friendRequestValidate(props?.friend_request_id).then(() => {
										setShowLoading(false);
										props.cdm();
										handleClose();
									});
								}}>
								Confirm request
							</button>
						)}
						{props.status === 'CREATED' && (
							<>
								<button
									className="btn btn-danger btn-rounded k-regular-15 mr-2 btn-block"
									style={{ border: 'none' }}
									onClick={() => {
										setShowLoading(true);
										friendRequestRefuse(props?.friend_request_id).then(() => {
											setShowLoading(false);
											props.cdm();
											handleClose();
										});
									}}>
									Decline Request
								</button>
								<button
									className="btn btn-yellow btn-rounded k-regular-15 mr-2 btn-block"
									style={{ border: 'none' }}
									onClick={() => {
										setShowLoading(true);
										friendRequestValidate(props?.friend_request_id).then(() => {
											setShowLoading(false);
											props.cdm();
											handleClose();
										});
									}}>
									confirm request
								</button>
							</>
						)}
						{props.status === 'VALIDATED' && (
							<button
								className="btn btn-danger btn-rounded k-regular-15 mr-2 btn-block"
								style={{ border: 'none' }}
								onClick={() => {
									setShowLoading(true);
									friendRequestRefuse(props?.friend_request_id).then(() => {
										setShowLoading(false);
										props.cdm();
										handleClose();
									});
								}}>
								Decline request
							</button>
						)}
						<button
							className="btn btn-light btn-rounded k-regular-15 btn-block"
							style={{ border: 'none' }}
							onClick={handleClose}>
							Cancel
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export function ModalDeleteMessage(props) {
	const [show, setShow] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	useEffect(() => {
		if (props.modalRefs) {
			props.modalRefs.current = handleShow;
		}
	}, []);
	return (
		<>
			{/* {props.child ? (
				props.child
			) : (
				// <span className="k-regular-13 pointer-cursor" onClick={handleShow}>
				// 	Supprimer la discussion
				// </span>
				<span className="k-regular-13 pointer-cursor" onClick={handleShow}>
					Delete  discussion
				</span>
			)} */}

			<Modal
				show={show}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				{showLoading && (
					<div
						style={{
							position: 'absolute',
							top: '50%',
							left: '44%',
							zIndex: 999,
						}}
						className="w-75 text-center">
						<ReactLoading
							loader={<img src={logoBee} alt=""/>}
							color={'#e8cc54'}
							height={'20%'}
							width={'20%'}
						/>
					</div>
				)}
				<Modal.Header className="row mx-0 justify-content-center">
					<img src={trash} width={`50px`} height={`50px`} alt="" />
					<p className="text-center k-semibold-20 w-100 pt-2">{props.title}</p>
				</Modal.Header>
				<Modal.Footer>
					<div className="col-12">
						<button
							className="btn btn-danger btn-rounded k-regular-15 mr-2 btn-block"
							style={{ border: 'none' }}
							onClick={() => {
								// setShowLoading(true);
								// console.log(`discussionId`, props.discussionId);
							}}>
							Delete discussion
						</button>
						<button
							className="btn btn-light btn-rounded k-regular-15 btn-block"
							style={{ border: 'none' }}
							onClick={handleClose}>
							Cancel
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}
