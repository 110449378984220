export const encryption = (number) => {
  const numberInteger = Number.parseInt(number ? number : 0);
  if (numberInteger >= 1000 && numberInteger < 1000000) {
    const remains = Number.parseInt(numberInteger / 1000);
    return `${remains}K`;
  }
  if (numberInteger >= 1000000 && numberInteger < 1000000000) {
    const remains = Number.parseInt(numberInteger / 1000000);
    return `${remains}M`;
  }
  if (numberInteger >= 1000000000) {
    const remains = Number.parseInt(numberInteger / 1000000000);
    return `${remains}B`;
  }
  return numberInteger;
};

export const encryptionNotification = (number) => {
  return number > 99 ? "+99" : number;
};

export const convert64IntoFile = (file64, mime) => {
  const byteString = atob(file64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mime });
};
