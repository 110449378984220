import { api } from '../../../res/constants';
import WS from './api';
import { toast } from 'react-toastify';
import {
	SET_PANIERS_COUNTER_ADD,
	SET_PANIERS_COUNTER_REMOVE,
	SET_PANIERS_COUNTER_RESET,
	SET_PANIERS_COUNTER,
} from './actionType';

export const createPanier = (params) => {
	const fd = new FormData();

	fd.append('productId', params.productId);
	fd.append('quantity', params.quantity);
	return new Promise((resolve, reject) => {
		WS.axiosSecurePost(fd, api.paniers).then(
			(res) => {
				// toast.success(`Ajouté à votre panier`);
				resolve(res.data);
			},
			(error) => {
				toast.error(`Erreur : Ajout au panier échoué`);
				reject(error);
			}
		);
	});
};

export const getAllPanier = () => {
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(api.paniers).then(
			(response) => {
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const updatePanier = (params, id) => {
	const fd = new FormData();
	fd.append('productId', params.productId);
	fd.append('quantity', params.quantity);
	return new Promise((resolve, reject) => {
		WS.axiosPutFormUrlEncoded(fd, `${api.paniers}/${id}`).then(
			(response) => {
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const deletePanierById = (id) => {
	return new Promise((resolve, reject) => {
		WS.axiosDeleteWithToken(`${api.paniers}/${id}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR DELETE user response : ', error);
				reject(error);
			}
		);
	});
};

export const emptyAllPaniers = () => {
	return new Promise((resolve, reject) => {
		WS.axiosDeleteWithToken(api.paniers).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR DELETE PANIERS : ', error);
				reject(error);
			}
		);
	});
};

// actions
export const setPaniersCounterAdd = (data) => {
	return {
		type: SET_PANIERS_COUNTER_ADD,
		data,
	};
};
export const setPaniersCounterRemove = (data) => {
	return {
		type: SET_PANIERS_COUNTER_REMOVE,
		data,
	};
};
export const setPaniersCounterReset = (data) => {
	return {
		type: SET_PANIERS_COUNTER_RESET,
		data,
	};
};
export const setPaniersCounter = (data) => {
	return {
		type: SET_PANIERS_COUNTER,
		data,
	};
};
