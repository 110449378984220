const ButtonComponent = ({ children, className, style, ...props }) => {
  return (
    <button
      className={`btn btn-rounded k-regular-15 mr-2 btn-block px-3 ${className}`}
      style={{ border: "none", ...style }}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonComponent;
