import React, { Suspense, lazy, useEffect, useState, useRef } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  withRouter,
} from "react-router-dom";
import "./Animated.css";
import { connect, useDispatch, useSelector } from "react-redux";
import logoBeee from "../assets/logoBeee.png";
// import ReactLoading from 'react-loading';
import PaymentCard from "../components/Payment/PaymentCardForm";
// import { grey } from '@material-ui/core/colors';
// import CheckoutFormPaypal from '../components/Payment/CheckoutFormPaypal';
import MessagesDetails from "../views/Messages/MessagesDetails";
import SocketClient from "../utils/socketjs";
import {
  getAllDicussions,
  newMessage,
  refreshDiscussion,
  setSelectedDiscussion,
  setSocketConnected,
  showAllDiscussion,
} from "../services/stores/actions/chat";
import { store } from "../App";
import {
  getCounter,
  incrementCouterMessage,
  incrementNotificationCount,
  setCouterMessage,
  setCouterNotification,
  setCouterOrder,
  setNotifications,
  setTotalCounter,
} from "../services/stores/actions/recentActivities";
import { updateNewItemsCount } from "../services/stores/actions/orders";
import { isConnected } from "../services/stores/selectors/selectors";
import ModalConditionExplicitContent from "../components/Modal/ModalConditionExplicitContent";
import ModalCookies from "../components/Modal/ModalCookies";
// import { updateUserAccount } from '../services/stores/actions/compte';
const AjoutProduitMobile = lazy(
  () => import("../components/AjoutProduit/AjoutProduitMobile")
);
const Header = lazy(() => import("../components/Header/Header"));
const DetailsUserScreen = lazy(
  () => import("../views/DetailsUser/DetailsUser")
);
const MyComponent = lazy(() => import("../views/NTF/Ntf"));
const DetailsContentScreen = lazy(
  () => import("../views/DetailsContent/DetailsContent")
);

const FooterMobile = lazy(
  () => import("../components/Footer/FooterMobile/FooterMobile")
);
const AjoutPublication = lazy(
  () => import("../views/AjoutPublication/AjoutPublication")
);
const Panier = lazy(() => import("../views/Panier/Panier"));

const Messages = lazy(() => import("../views/Messages/Messages"));
const DetailsMessage = lazy(
  () => import("./../views/DetailsMessage/DetailsMessage")
);

const AjoutPublicationMobile = lazy(
  () =>
    import(
      "../views/AjoutPublication/AjoutPublicationMobile/AjoutPublicationMobile"
    )
);

const ListingContents = lazy(
  () => import("../views/ListingContents/ListingContents")
);
const MonCompte = lazy(() => import("../views/MonCompte/MonCompte"));
const MonProfil = lazy(() => import("../views/MonCompte/MonProfil"));
const CustomPage = lazy(() => import("../components/CustomPage/CustomPage"));
const CGU = lazy(() => import("../views/CGU/cgu"));
const DetailsProduit = lazy(
  () => import("../views/DetailsProduit/DetailsProduit")
);
const UpdateProduct = lazy(() => import("../views/Update/UpdateProduct"));

const UpdateContent = lazy(() => import("../views/Update/UpdateContent"));
const SearchPage = lazy(
  () => import("../components/HandlerComponentsImport/HandlerSearchPage")
);

const Orders = lazy(() => import("../views/Orders/Orders"));

function CustomRouter(props) {
  const isConnect = props.isConnect;
  const socketConnected = useSelector(
    (state) => state.chatReducers.socketConnected
  );

  const dispatch = useDispatch();
  const policeRef = useRef(false);
  const showModal = !isConnect && !props.validationContent;
  const connectionSocket = async () => {
    dispatch(setSocketConnected(false));
    try {
      const stompClient = await SocketClient.connect();
      if (stompClient) {
        dispatch(setSocketConnected(true));

        stompClient.subscribe("/user/topic/new.message", (response) => {
          if (!policeRef.current) {
            policeRef.current = true;
            const payload = JSON.parse(response.body);
            store.dispatch(newMessage(payload));
            getAllDicussions()
              .then((data) => {
                store.dispatch(showAllDiscussion(data.data.content));

                if (data.data.content[0].notSeenMessages === 1) {
                  store.dispatch(incrementCouterMessage());
                  store.dispatch(
                    setTotalCounter(
                      store.getState().counterReducers.totalCount + 1
                    )
                  );
                }
              })
              .catch((_err) => {});

            let msgLB = document.getElementById("messageLastBottom");
            if (msgLB) {
              msgLB.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }
            setTimeout(() => {
              policeRef.current = false;
            }, 500);
          }
        });

        stompClient.subscribe("/user/topic/private.order", (response) => {
          store.dispatch(refreshDiscussion(true));
        });
        stompClient.subscribe("/user/topic/new.comment", (response) => {
          const payload = JSON.parse(response.body);
        });

        stompClient.subscribe("/user/topic/notification", (response) => {
          const payload = JSON.parse(response.body);
          store.dispatch(incrementNotificationCount());
          store.dispatch(
            setTotalCounter(store.getState().counterReducers.totalCount + 1)
          );
        });
        stompClient.subscribe("/user/topic/new.order", (response) => {
			const payload = JSON.parse(response.body);
			if (payload) {
            store.dispatch(
              updateNewItemsCount(
                store.getState().counterReducers.newItemsCount + 1
              )
            );
            store.dispatch(
              setTotalCounter(store.getState().counterReducers.totalCount + 1)
            );
          }
        });
      } else {
        dispatch(setSocketConnected(false));
        if (localStorage.getItem("token")) {
          connectionSocket();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (socketConnected) {
      getCounter().then((res) => {
        let total =
          parseInt(res.data.data.notification) +
          parseInt(res.data.data.message) +
          parseInt(res.data.data.orders) +
          parseInt(res.data.data.friend);

        dispatch(setTotalCounter(total));
        dispatch(setCouterMessage(res.data.data.message));
        dispatch(updateNewItemsCount(res.data.data.orders));
        dispatch(setCouterNotification(res.data.data.notification));
      });
    }
  }, [socketConnected]);

  useEffect(() => {
    if (isConnect) {
      setTimeout(() => {
        connectionSocket();
      }, [1000]);
    }
  }, [isConnect]);

  return (
		<div
			className="container-fluid h-100 m-0 p-0 scrollable100">
			<>
				<Router>
					<Suspense
						fallback={
							<div className="text-center pulse">
								<img src={logoBeee} alt="Chargement..." className="bee" />
							</div>
						}>
					  <Header />
					  {/* <ModalCookies/> */}

						<div className="height100">
							<Switch>
								<Route
									exact
									path="/"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/live"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/main-details-user"
									component={withRouter(DetailsUserScreen)}
								/>
								<Route
									exact
									path="/Photos"
									render={(props) => {
										return (
											<>
												<ListingContents
													key={props.match.params.step}
													{...props}
												/>
												{showModal && <ModalConditionExplicitContent />}
											</>
										);
									}}
								/>
								<Route
									exact
									path="/Shopping"
									render={(props) => {
										return (
											<>
												<ListingContents
													key={props.match.params.step}
													{...props}
												/>
												{showModal && <ModalConditionExplicitContent />}
											</>
										);
									}}
								/>
								<Route
									exact
									path="/Videos"
									render={(props) => {
										return (
											<>
												{showModal && <ModalConditionExplicitContent />}
												<ListingContents
													key={props.match.params.step}
													{...props}
												/>
											</>
										);
									}}
								/>
								<Route
									exact
									path="/Tags"
									render={(props) => {
										return (
											<>
												{showModal && <ModalConditionExplicitContent />}
												<ListingContents
													key={props.match.params.step}
													{...props}
												/>
											</>
										);
									}}
								/>
								<Route
									exact
									path="/registration/:val"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/login"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/motDePasseOublier"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/new-publication-app/:step"
									render={(props) => (
										<>
											<AjoutPublicationMobile
												key={props.match.params.step}
												{...props}
											/>
										</>
									)}
								/>
								<Route
									exact
									path="/MonCompte"
									render={(props) =>
										isConnect ? (
											<>
												<MonCompte key={props.match.params.step} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/new-publication"
									render={(props) =>
										isConnect ? (
											<>
												<AjoutPublication
													key={props.match.params.step}
													{...props}
												/>
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/messages"
									render={(props) =>
										isConnect ? (
											<>
												<Messages key={props.match.params.step} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/MonProfil"
									render={(props) =>
										isConnect ? (
											<>
												<MonProfil key={props.match.params.step} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/Lives"
									render={(props) => (
										<>
											<ListingContents
												key={props.match.params.step}
												{...props}
											/>
										</>
									)}
								/>
								<Route
									exact
									path="/Hashtags/:val"
									render={(props) => {
										return (
											<>
												{showModal && <ModalConditionExplicitContent />}
												<CustomPage key={props.match.params.val} {...props} />
											</>
										);
									}}
								/>
								<Route
									exact
									path="/AjoutProduitMobile/:val"
									render={(props) =>
										isConnect ? (
											<div className="scrollable100">
												<AjoutProduitMobile
													key={props.match.params.val}
													{...props}
												/>
											</div>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/details-content/:id"
									render={(props) => {
										return (
											<>
												{showModal && <ModalConditionExplicitContent />}
												<DetailsContentScreen
													key={props.match.params.val}
													{...props}
												/>
											</>
										);
									}}
								/>
								<Route
									exact
									path="/details-user/:val"
									render={(props) => {
										return (
											<>
												{showModal && <ModalConditionExplicitContent />}
												<CustomPage key={props.match.params.val} {...props} />
											</>
										);
									}}
								/>
								<Route
									exact
									path="/details-produit/:val"
									render={(props) => {
										return (
											<>
												{showModal && <ModalConditionExplicitContent />}
												<DetailsProduit
													key={props.match.params.val}
													{...props}
												/>
											</>
										);
									}}
								/>
								<Route
									exact
									path="/searchPageMobile"
									component={withRouter(SearchPage)}
								/>
								<Route
									exact
									path="/formSignaler/:val"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/formBloquer/:val"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/formBloquerInDiscussion/:val"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/Validation"
									render={(props) => (
										<CustomPage key={props.match.params.val} {...props} />
									)}
								/>
								<Route
									exact
									path="/CGU"
									render={(props) => (
										<CGU key={props.match.params.step} {...props} />
									)}
								/>
								<Route exact path="/NTF" component={MyComponent} />
								{/* Connected Page */}
								<Route
									exact
									path="/details-message/:id"
									render={(props) =>
										isConnect ? (
											<>
												<MessagesDetails
													key={props.match.params.val}
													{...props}
												/>
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/Wishlist"
									render={(props) =>
										isConnect ? (
											<CustomPage key={props.match.params.step} {...props} />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/messages"
									render={(props) =>
										isConnect ? (
											<>
												<Messages key={props.match.params.step} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/notification"
									render={(props) =>
										isConnect ? (
											<CustomPage key={props.match.params.step} {...props} />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/ViePrivee"
									render={(props) =>
										isConnect ? (
											<CustomPage key={props.match.params.step} {...props} />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/Panier/:val"
									render={(props) =>
										isConnect ? (
											<>
												<Panier key={props.match.params.val} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/Paiement/:val"
									render={(props) =>
										isConnect ? (
											<CustomPage key={props.match.params.step} {...props} />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/DetailsProduit/:val"
									render={(props) =>
										isConnect ? (
											<>
												<DetailsProduit
													key={props.match.params.val}
													{...props}
												/>
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/UpdateProduct/:val"
									render={(props) =>
										isConnect ? (
											<div className="scrollable100">
												<UpdateProduct
													key={props.match.params.val}
													{...props}
												/>
											</div>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/UpdateContent/:val"
									render={(props) =>
										isConnect ? (
											<div className="scrollable100">
												<UpdateContent
													key={props.match.params.val}
													{...props}
												/>
											</div>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/PaymentCard"
									render={(props) =>
										isConnect ? (
											<>
												<PaymentCard key={props.match.params.val} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>{' '}
								<Route
									exact
									path="/paymentpaypal"
									render={(props) =>
										isConnect ? (
											<CustomPage key={props.match.params.step} {...props} />
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route
									exact
									path="/ValidationPaymentCard"
									render={(props) =>
										isConnect ? (
											<CustomPage key={props.match.params.val} {...props} />
										) : (
											<Redirect to="/login" />
										)
									}
								/>{' '}
								<Route
									exact
									path="/orders"
									render={(props) =>
										isConnect ? (
											<>
												<Orders key={props.match.params.val} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
								<Route path="*">
									<NoMatch />
								</Route>
								<Route
									exact
									path="*"
									render={(props) =>
										isConnect ? (
											<>
												<MonCompte key={props.match.params.step} {...props} />
											</>
										) : (
											<Redirect to="/login" />
										)
									}
								/>
							</Switch>
						</div>
						<FooterMobile />
					</Suspense>
				</Router>
			</>
		</div>
	);
}

function NoMatch() {
  let location = useLocation();

  return (
    <div className="k-semibold-20 text-center">
      <h3>
        Url introuvable <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
const mapStateToProps = (state) => ({
  isConnect: state.compteReducers.isConnected,
  user: state.compteReducers.userConnected,
  validationContent: state.compteReducers.acceptedCondition,
});

export default connect(mapStateToProps)(CustomRouter);
